import React, { useState, Fragment, useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { AdminChestsWrap } from '../adminChests/styles/AdminChestsWrap';
import { TeamForm } from '../../forms/TeamForm';
import { AddTeamOptions } from './AddTeamOptions';
import { AdminChestAddWrap, FakeArea } from '../adminChests/styles/AdminChestAddStyles';
import { SaveFooter } from '../adminFooter/footers/SaveFooter';
import { getValidation } from '../../../utils/validationSuite/validationIndex';
import { useNotification } from '../../../common/notification/Notification';
import { ChestTableData } from '../../../api/chests/ChestApiFunctions';
import { UserTableData } from '../../../api/users/UserApiFunctions';
import { ViewTeamData, TeamTableData } from '../../../api/teams/TeamApiFunctions';
import BaseApi from '../../../api/BaseApi';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { reshareData } from '../../../api/secretKeys/SecretKeyApiFunctions';
import GenericFormLoading from '../../../common/genericLoading/GenericFormLoading';
import { AdminContext } from '../../../context/AdminContext';

/* Component that handles the process of editing a team using the
    team form */
const AdminTeamEdit = () => {
  const [teamName, setTeamName] = useState('');
  const [chests, setChests] = useState([]);
  const [users, setUsers] = useState([]);
  const [prevAssignments, setPrevAssignments] = useState([]);
  const [prevMemberships, setPrevMemberships] = useState([]);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const setTeamTags = useContext(AdminContext).teamTags[1];
  const location = useLocation();
  const row = useMemo(() => location['row'] || (location['state'] && location['state']['row']) || [], [location]);
  const history = useHistory();

  const { setNotificationText } = useNotification();

  /* useEffect that handles the preparation of data based on the row id
      to fill in the form as soon as it is rendered. */
  useEffect(() => {
    // Filters the chests for ones already added based on the assignments.
    const filterChests = (assignments, chestArray) => {
      assignments.forEach(assignment => {
        const chestIndex = chestArray.findIndex(chest => chest.id === assignment.vault_id);
        chestArray[chestIndex].edit_access = assignment.edit_access;
        chestArray[chestIndex].added = true;
      });
      setChests(chestArray);
    };

    // Filters the users based on the memberships already added.
    const filterUsers = (memberships, userArray) => {
      memberships.forEach(membership => {
        const userIndex = userArray.findIndex(user => user.id === membership.user_id);
        if (userArray[userIndex]) userArray[userIndex].added = true;
      });
      setUsers(userArray);
    };

    let isMounted = true;
    if (row.length !== 0) {
      ViewTeamData(row.id).then(async team => {
        if (team) {
          if (isMounted) setTeamName(team.name);
          if (isMounted) setPrevAssignments(team.assignments);
          if (isMounted) setPrevMemberships(team.memberships);
          const allChests = await ChestTableData({ everything: true });
          const chestsArray = allChests.map(chest => {
            return {
              id: chest.id,
              name: chest.name,
              secrets: chest.secrets,
              added: false,
              selected: false,
              edit_access: false,
            };
          });
          filterChests(team.assignments, chestsArray);
          const users = await UserTableData({ everything: true });
          const usersArray = users.map(user => {
            return {
              id: user.id,
              name: user.name,
              email: user.email,
              added: false,
              selected: false,
            };
          });
          filterUsers(team.memberships, usersArray);
        } else {
          if (isMounted) setRedirect(true);
        }
      });
    }
    return () => (isMounted = false);
  }, [row]);

  // Function for preparing the JSON data related to the assignments.
  const updateAssignments = addedChests => {
    let chestData = [];
    prevAssignments.forEach(assignment => {
      const chestIndex = addedChests.findIndex(chest => chest.id === assignment.vault_id);
      if (!addedChests[chestIndex]) {
        chestData.push({
          id: assignment.id,
          _destroy: 1,
        });
      } else {
        chestData.push({
          id: assignment.id,
          edit_access: addedChests[chestIndex].edit_access,
        });
      }
      addedChests.splice(chestIndex, 1);
    });
    addedChests.forEach(chest => {
      chestData.push({
        vault_id: chest.id,
        edit_access: chest.edit_access,
      });
    });
    return chestData;
  };

  // Function for preparing the JSON data related to the memberships.
  const updateMemberships = addedUsers => {
    let userData = [];
    prevMemberships.forEach(membership => {
      const userIndex = addedUsers.findIndex(user => user.id === membership.user_id);
      if (!addedUsers[userIndex]) {
        userData.push({
          id: membership.id,
          _destroy: 1,
        });
      } else {
        userData.push({ id: membership.id });
        addedUsers.splice(userIndex, 1);
      }
    });
    addedUsers.forEach(user => {
      userData.push({ user_id: user.id });
    });
    return userData;
  };

  /* Function that handles the validation of the data, the team update API call
    and the resharing of secrets */
  const updateTeam = () => {
    const validationId = 'TeamForm';
    const addedChests = chests.filter(chest => chest.added === true);
    const addedUsers = users.filter(user => user.added === true);
    const params = {
      data: { teamName, addedChests, addedUsers },
      setNotificationText,
    };
    if (getValidation(validationId)(params)) {
      setLoading(true);
      const chestData = updateAssignments(addedChests);
      const userData = updateMemberships(addedUsers);
      const data = {
        team: {
          name: teamName,
          memberships_attributes: userData,
          assignments_attributes: chestData,
        },
      };
      BaseApi.update('teams', row.id, data).then(async () => {
        userData.forEach(async user => {
          if (user.user_id) await reshareData(user.user_id);
        });
        setTeamTags(await TeamTableData({ everything: true }));
        history.push({
          pathname: '/admin/teams',
          prevPathname: location.pathname,
        });
      });
    }
  };

  // useEffect for keyboard shortcuts
  useEffect(() => {
    Mousetrap.bind('shift+s', shiftS);
    Mousetrap.bind('enter', enterSC);
    Mousetrap.bind('esc', history.goBack);
    return () => {
      Mousetrap.unbind('shift+s');
      Mousetrap.unbind('enter');
      Mousetrap.unbind('esc');
    };
  });
  const enterSC = () => {
    if (document.activeElement === document.getElementsByClassName('footer-button')[0]) {
      updateTeam();
    } else if (document.activeElement === document.getElementsByClassName('footer-button')[1]) {
      history.goBack();
    }
  };
  const shiftS = () => {
    return document.activeElement === document.getElementById('team-name-input') ? null : updateTeam();
  };

  if (row.length === 0) return <Redirect to={'/admin/teams'} />;
  if (redirect) return <LogoutRedirect />;
  return (
    <Fragment>
      {loading ? (
        <GenericFormLoading />
      ) : (
        <Fragment>
          <AdminChestsWrap className="form-footer team-form">
            <AdminChestAddWrap>
              <TeamForm
                teamName={teamName}
                setTeamName={setTeamName}
                chests={chests}
                setChests={setChests}
                users={users}
                setUsers={setUsers}
              />
              <SaveFooter handleSubmit={() => updateTeam()} edit={true} />
            </AdminChestAddWrap>
            <AddTeamOptions />
          </AdminChestsWrap>
          <FakeArea></FakeArea>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AdminTeamEdit;
