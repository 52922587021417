import React, { Fragment, useState, useEffect, useContext, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import Mousetrap from 'mousetrap';
import SearchBar from '../../common/searchBar/SearchBar';
import ListTransition from './styles/TransitionStyle';
import FooterLink from '../../common/links/FooterLink';
import { FooterTagWrap } from '../../common/links/styles/FooterLinkStyle';
import Table from '../table/Table';
import { ViewSecret } from '../secrets/ViewSecret';
import { TableWrap } from '../table/styles/TableStyles';
import { TableBottomLoading } from '../table/subComponents/styles/TableBottomLoading';
import { SecretTableData } from '../../api/secrets/SecretApiFunctions';
import LogoutRedirect from '../../common/nav/LogoutRedirect';
import Loading from '../../images/loading_02.gif';
import { scrollEnded, focus } from '../../utils/commonFunctions';
import debounce from 'lodash.debounce';
import { UserContext } from '../../context/UserProvider';

const Secrets = ({ stateObject }) => {
  const {
    display,
    setDisplay,
    searchText,
    setSearchText,
    setAddSecret,
    addSecret,
    viewSecret,
    setViewSecret,
    secretData,
    setSecretData,
  } = stateObject;
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const searchBarRef = useRef(null);

  const headers = {
    Icon: 'lock',
    Name: 'name',
    Type: 'type',
    Chest: 'chest',
    Actions: 'secretActions',
  };
  const columnWidths = ['5%', '30%', '20%', '30%', '15%'];
  const [tableData, setTableData] = useState([]);
  const [offset, setOffset] = useState(0);
  const { user } = useContext(UserContext);
  const { administrator } = user;

  const jsonLimit = 20;

  if (searchText !== '' && display === false) setDisplay(true);

  const setSearch = debounce(setSearchText, 400);

  useEffect(() => {
    !addSecret && setSearchText('');
  }, [addSecret, setSearchText]);

  useEffect(() => {
    const getTableData = async () => {
      const res = await SecretTableData({
        filter: searchText,
        limit: jsonLimit,
        offset: 0,
        options: ['vault'],
      });
      setTableData(res);
      setLoading(false);
    };
    if (display === true) getTableData();
  }, [loading, setLoading, searchText, display]);

  const handleScrollToBottom = async e => {
    const target = e.target;
    if (scrollEnded(target)) {
      const tableTotal = tableData[0] && tableData[0]['total'];
      const newOffset = offset + jsonLimit;
      if (newOffset < tableTotal) {
        setDataLoading(true);
        setOffset(newOffset);
        const data = await SecretTableData({
          filter: searchText,
          limit: jsonLimit,
          offset: newOffset,
          options: ['vault'],
        });
        data.length !== 0 ? setTableData([...tableData, ...data]) : setOffset(newOffset - jsonLimit);
        setDataLoading(false);
      }
    }
  };

  useEffect(() => {
    Mousetrap.bind('i', () => focus('searchbar'), 'keyup');
    return () => {
      Mousetrap.unbind('i', 'keyup');
    };
  });

  const renderSecret = () => {
    return (
      <TableWrap className={'view'}>
        <ViewSecret
          secret={viewSecret}
          setViewSecret={setViewSecret}
          isAdmin={administrator}
          secretData={secretData}
          setSecretData={setSecretData}
        />
      </TableWrap>
    );
  };

  const renderTable = () => (
    <CSSTransition classNames="content" in={display} timeout={200} unmountOnExit>
      <TableWrap role={'table-wrap'} onScroll={e => handleScrollToBottom(e)} tabIndex="-1">
        <Table
          columnWidths={columnWidths}
          headers={headers}
          data={tableData}
          setViewSecret={setViewSecret}
          showPopular={true}
          params={{ loading, setLoading }}
        />
      </TableWrap>
    </CSSTransition>
  );

  if (!tableData) return <LogoutRedirect />;
  return (
    <Fragment>
      <SearchBar
        placeholder="Search for a Secret"
        setSearchText={e => {
          setViewSecret(null);
          setSearch(e);
        }}
        ref={searchBarRef}
      />
      <ListTransition>
        {viewSecret ? renderSecret() : renderTable()}
        {dataLoading && (
          <TableBottomLoading>
            <img className="small-loading" src={Loading} alt="Loading..." />
          </TableBottomLoading>
        )}
        <FooterTagWrap>
          <FooterLink
            text={'Show all secrets'}
            display={display}
            setDisplay={setDisplay}
            setAddSecret={setAddSecret}
            onClick={() => searchBarRef.current.focus()}
          />
        </FooterTagWrap>
      </ListTransition>
    </Fragment>
  );
};

export default Secrets;
