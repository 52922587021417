import styled from 'styled-components';

const WrapperFieldset = styled.fieldset`
  background-color: inherit;
  position: relative;
  margin: 0 1.25rem 1.5rem 0;
  padding: 0;
  border: 1px solid ${props => props.theme.fieldBorderColor};
  border-radius: 5px;

  &:focus-within {
    border-color: #40dae1;
    legend {
      color: #40dae1;
    }
  }

  &.error {
    border-color: ${props => props.theme.error};
  }

  &.dropdown {
    cursor: pointer;
  }

  &.dropdown-opened {
    border-radius: 5px 5px 0 0;
    border: 1px solid ${props => props.theme.highlightColor};
  }

  &.no-right-margin {
    margin-right: 0;
  }

  &.country-code {
    margin: 0.5rem 0 1rem 0 !important;
    background-color: ${props => props.theme.formBgColor};
  }
`;

export const FullWrapperFieldset = styled(WrapperFieldset)`
  width: 100%;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const HalfWrapperFieldset = styled(WrapperFieldset)`
  width: 45%;
  width: calc(50% - 1.25rem);
  min-width: 85px;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`;

export const QuarterWrapperFieldset = styled(WrapperFieldset)`
  width: 22.5%;
  width: calc(25% - 1.25rem);
  min-width: 85px;

  &:focus-within {
    border-color: #40dae1;
    legend {
      color: #40dae1;
    }
  }
`;

export const Legend = styled.legend`
  background-color: inherit;
  color: ${props => props.theme.fieldBorderColor};
  font-size: 0.9rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.75rem;
  transition: color 0.1s ease-in-out, top 0.1s ease-in-out;
  position: absolute;
  top: -0.65rem;
  text-transform: capitalize;
  line-height: normal;

  &.empty {
    opacity: 0;
  }

  &.error {
    color: ${props => props.theme.error};
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  &:focus-within i {
    color: ${props => props.theme.highlightColor};
  }
`;

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  background-color: inherit;
  font-family: 'Lato';
  border: none;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  color: ${props => props.theme.fieldTextColor};
  outline: none;

  ::placeholder {
    color: ${props => props.theme.placeholderColor};
  }

  &.dropdown {
    display: flex;
    cursor: inherit;
    min-width: 0;
  }

  &#payment-country-dropdown {
    padding: 1rem;
    font-size: 0.9rem;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-right: 1.25rem;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${props => props.theme.highlightColor};
  }
`;

export const ErrorMessage = styled.span`
  color: ${props => props.theme.error};
  font-size: 1rem;
`;
