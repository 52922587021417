import React from 'react';
import leftPanel from '../../images/leftPanel.png';
import { LeftContainer, LeftBackground } from './styles/LayoutStyles';

export const LeftScreen = () => {
  return (
    <LeftContainer>
      <LeftBackground src={leftPanel} />
    </LeftContainer>
  );
};
