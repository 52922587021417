import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { useNotification } from '../../common/notification/Notification';
import {
  ProfileContainer,
  ProfileHeader,
  ProfileHeadText,
  ProfileFooter,
  ProfileBody,
} from './styles/ProfileSettingsStyles';
import FooterTag from '../../common/links/FooterTag';
import { Icon } from '../../common/Icon';
import Setting from '../../common/settings/Setting';
import ProfileApi from '../../api/profiles/ProfileApi';
import { ReencryptAndSubmit, ChangeAccountPassword } from '../../utils/AccountFunctions';
import UserApi from '../../api/users/UserApi';
import ChangeEmail from './subComponents/ChangeEmail';
import ChangePassword from './subComponents/ChangePassword';
import { ProfileModalOverlay, ProfileModalContainer } from './subComponents/styles/ProfileModalStyles';
import { ThemeContext } from 'styled-components';
import { placeholderFunc } from '../../utils/commonFunctions';
import TwoFactorApi from '../../api/twofactor/TwoFactorApi';
import { UserContext } from '../../context/UserProvider';
import { ModalContext } from '../../context';

const ProfileSettings = () => {
  const themeContext = useContext(ThemeContext);
  const { setModal: setNewModal } = useContext(ModalContext);
  const { user, update, setUser } = useContext(UserContext);
  const { setNotificationText } = useNotification();

  const [modal, setModal] = useState('');
  const [profileName, setProfileName] = useState(user.name);
  const [activeSetting, setActiveSetting] = useState('');

  const history = useHistory();
  const goBack = () => history.goBack();

  const handleEsc = () =>
    modal === '' &&
    history.push({
      pathname: history.location.prevPathname,
      prevPathname: history.location.pathname,
    });

  // Keyboard shortcuts
  const profileMousetrap = new Mousetrap();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      profileMousetrap.bind('esc', handleEsc, 'keyup');
    }
    return () => {
      isMounted = false;
      profileMousetrap.unbind('esc', 'keyup');
    };
  });

  const editProfileName = () => {
    const params = {
      user: { name: profileName },
    };
    update('name', profileName);
    ProfileApi.update(params).then(async () => setUser(await ProfileApi.show()));
  };

  const editProfileEmail = (password, email) => {
    ReencryptAndSubmit(user.email, password, email, password).then(async result => {
      const params = {
        user: {
          email: result.email,
          password: result.password,
          current_password: result.current_password,
          encrypted_private_key: result.encrypted_private_key,
          private_key_iv: result.private_key_iv,
        },
      };
      await UserApi.change(params);
    });
  };

  const resetModal = () => setModal('');

  const twoStepText = () => {
    if (!user) return '';
    if (!user['otp_required_for_login']) return 'Enable two-step verification';

    return 'Disable two-step verification';
  };

  const twoStepFunc = () => {
    if (!user) return placeholderFunc();
    if (user['otp_required_for_login'])
      return TwoFactorApi.disableOtp().then(res => {
        if (res['2fa'].includes('Success')) {
          setNotificationText({ text: res['2fa'] });
          setNewModal();
          return update('otp_required_for_login', false);
        }
        setNotificationText({ type: 'error', text: res['2fa'] });
        setNewModal();
      });
    history.push({
      pathname: '/profile/twofactor',
    });
    setNewModal();
  };

  const twoStepModal = () =>
    user['otp_required_for_login']
      ? setNewModal({
          key: 'Confirm',
          params: {
            icon: 'alert',
            question: `Are you sure you want to disable 2FA?`,
            subtext: 'This will allow anybody with your account password to access the account.',
            confirmAction: twoStepFunc,
            cancelAction: () => setNewModal(),
          },
        })
      : twoStepFunc();

  return (
    <ProfileContainer>
      <ProfileHeader>
        <ProfileHeadText>
          {themeContext.scheme === 'dark' ? (
            <Icon name="settings_white" width="20px" />
          ) : (
            <Icon name="settings" width="20px" />
          )}
          Account Settings
        </ProfileHeadText>
        <hr />
      </ProfileHeader>
      <ProfileBody>
        <Setting
          name="Display Name"
          activeSetting={activeSetting}
          setActiveSetting={setActiveSetting}
          type="input"
          text={profileName}
          setText={setProfileName}
          buttonName="Edit"
          onClick={() => editProfileName()}
        />
        <Setting
          name="Email"
          activeSetting={activeSetting}
          setActiveSetting={setActiveSetting}
          type="input"
          text={user.email}
          buttonName="Edit"
          readOnly={true}
          onClick={() => setModal('EMAIL')}
        />
        <Setting
          name="Password"
          activeSetting={activeSetting}
          setActiveSetting={setActiveSetting}
          type="input"
          text={'*************'}
          buttonName="Change Password"
          readOnly={true}
          onClick={() => setModal('PASSWORD')}
        />
        <hr />
        <Setting
          name="Two-Step Verification"
          activeSetting={activeSetting}
          setActiveSetting={setActiveSetting}
          type="text"
          text={'Protect your account from un-authorized access by enabling Two-Factor Verification.'}
          buttonName={twoStepText()}
          readOnly={true}
          onClick={twoStepModal}
        />
      </ProfileBody>
      <ProfileFooter>
        <FooterTag
          onClick={goBack}
          fontIcon="fas fa-arrow-alt-circle-left"
          text="Go back"
          keyText=""
          icon={<Icon name="esc" className="esc-key" width="65px" />}
        />
      </ProfileFooter>
      {modal === 'EMAIL' && (
        <ChangeEmail
          oldEmail={user.email}
          handleConfirm={data => editProfileEmail(data.password, data.email)}
          handleCancel={() => setModal('')}
        />
      )}
      {modal === 'PASSWORD' && (
        <Fragment>
          <ProfileModalOverlay />
          <ProfileModalContainer>
            <ChangePassword
              title="Change Password"
              email={user.email}
              handleConfirm={data => ChangeAccountPassword(data.oldPass, data.newPass, user.email, resetModal)}
              handleCancel={() => setModal('')}
            />
          </ProfileModalContainer>
        </Fragment>
      )}
    </ProfileContainer>
  );
};

export default ProfileSettings;
