import React from 'react';
import { placeholderFunc } from '../../utils/commonFunctions';
import { WelcomeButtonWrap, IconWrap, TextWrap } from './styles/WelcomeButtonStyles';

const WelcomeButton = ({ disabled, text = 'Placeholder', icon = '+', onClick = placeholderFunc }) => (
  <WelcomeButtonWrap
    role={'WelcomeButton'}
    disabled={disabled}
    onClick={onClick}
    tabIndex={1}
    className={!disabled && 'tutorialButton'}
  >
    <IconWrap role={'welcome-button-icon'}>{icon}</IconWrap>
    <TextWrap>{disabled ? `${text} (optional)` : text}</TextWrap>
  </WelcomeButtonWrap>
);

export default WelcomeButton;
