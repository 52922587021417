import React from 'react';
import { useLocation } from 'react-router-dom';
import { placeholderFunc } from '../../../../utils/commonFunctions';
import GenericTag from '../../../../common/links/styles/GenericTag';
import { determineContext } from '../../adminFunctions/pageFunctions';
import { dropdownMetric } from '../../adminFunctions/dropdownFunctions';
import { DropdownOptionStyle, DropdownAltText } from './styles/DropdownOptionStyles';

const DropdownOption = ({ tag = 'placeholder', onClick = placeholderFunc, currentActive }) => {
  const location = useLocation();
  const pathname = location['pathname'] || null;
  const context = determineContext(pathname);
  const metric = dropdownMetric[context];
  const metricLowerCase = metric.toLowerCase();

  return (
    <DropdownOptionStyle onClick={onClick} currentActive={currentActive}>
      <GenericTag currentActive={currentActive} maxWidth="60%">
        {tag['name']}
      </GenericTag>
      <DropdownAltText>{`${tag[metricLowerCase]} ${metric}`}</DropdownAltText>
    </DropdownOptionStyle>
  );
};

export default DropdownOption;
