import { createContext, Dispatch, ReactNode, SetStateAction } from 'react';
import LocalStore from '../api/LocalStore';
import Dummy from '../utils/classes/Dummy';

interface IProps {
  user: RecryptUser;
  setUser: Dispatch<SetStateAction<RecryptUser>>;
  children: ReactNode;
}

interface IContext {
  user: RecryptUser;
  setUser: Dispatch<SetStateAction<RecryptUser>>;
  logout: any;
  switchTheme: () => any;
  update: (attr: string, val: any) => void;
}

const initialState: IContext = {
  user: new Dummy().user(),
  setUser: () => new Dummy().user(),
  logout: () => undefined,
  switchTheme: () => undefined,
  update: () => null,
};

export const UserContext = createContext(initialState);

const UserProvider = ({ user, setUser, children }: IProps) => {
  const store = new LocalStore();

  const logout = () => {
    store.clear();
    store.set('dark_mode', user.dark_mode || true);
    setUser(new Dummy().user());
  };

  const switchTheme = () => {
    store.set('dark_mode', !user.dark_mode);
    setUser({ ...user, dark_mode: !user.dark_mode });
  };

  const update = (attr: string, val: any) => setUser({ ...user, [attr]: val });

  return <UserContext.Provider value={{ user, setUser, logout, switchTheme, update }}>{children}</UserContext.Provider>;
};

export default UserProvider;
