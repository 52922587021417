import React, { useContext } from 'react';
import { FirstSecretWrapper } from './styles/FirstSecretStyles';
import SecretForm from '../forms/SecretForm';
import { WelcomeContext } from '../../context/index';
import { FormHeader } from '../forms/styles/FormHeader';

export const FirstSecret = () => {
  const { welcome, setWelcome } = useContext(WelcomeContext);

  const handleForm = () => {
    setWelcome({
      tutorial: welcome.tutorial,
      twofactor: welcome.twofactor,
      secret: true,
    });
  };

  return (
    <FirstSecretWrapper>
      <FormHeader>Add your first Secret</FormHeader>
      <SecretForm first={handleForm} admin={true} />
    </FirstSecretWrapper>
  );
};
