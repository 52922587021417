import React from 'react';
import {
  DropdownWrap,
  DropdownButtonWrap,
  DropdownButtonBar,
  OptionsContainer,
  DropdownOptionsWrap,
} from './styles/AdminDropdownStyles';
import DropdownButtonIcon from './subComponents/DropdownButtonIcon';
import DropdownButtonTitle from './subComponents/DropdownButtonTitle';
import { DropdownButtonPlus } from './subComponents/DropdownButtonPlus';
import { useHistory, useLocation } from 'react-router-dom';
import { generateTags, droppedStatus } from '../../adminFunctions/dropdownFunctions';
import { placeholderFunc } from '../../../../utils/commonFunctions';
import { PlusOptions } from './subComponents/PlusOptions';
import DeletedSecretsButton from './subComponents/DeletedSecretsButton';

const AdminDropdown = ({
  barText = '',
  linkTo = '/dropdownDefault',
  tags,
  icon = '+',
  setContext = placeholderFunc,
  context = '',
  options,
  toggleOptions,
  optionsVisible,
  currentActiveTag,
  active,
}) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dropped = droppedStatus(linkTo, pathname);
  const url = `${linkTo}/show`;

  const handleClickBar = () => {
    history.push({ pathname: linkTo, prevPathname: pathname });
    setContext(barText.includes('Chests') ? 'Chests' : 'Teams');
  };

  const handleClickPlus = () => {
    optionsVisible !== barText ? toggleOptions(barText) : toggleOptions('');
  };

  const redirectTo = row => history.push({ pathname: url, row, context, prevPathname: pathname });

  const goToDeleted = () => history.push({ pathname: '/admin/chests/deleted', context });

  return (
    <DropdownWrap>
      <DropdownButtonWrap className={active && 'dd-active'}>
        <DropdownButtonBar role={'button-bar'} className={active && 'active'} onClick={handleClickBar}>
          <DropdownButtonIcon className={active && 'active'} icon={icon} />
          <DropdownButtonTitle barText={barText} dropped={dropped} />
        </DropdownButtonBar>
        <OptionsContainer className={active && 'active'} onMouseLeave={() => toggleOptions('')}>
          <DropdownButtonPlus onClick={handleClickPlus} />
          {optionsVisible === barText && <PlusOptions options={options} />}
        </OptionsContainer>
      </DropdownButtonWrap>
      <DropdownOptionsWrap>
        {dropped ? generateTags(tags, redirectTo, currentActiveTag) : null}
        {dropped && linkTo === '/admin/chests' ? (
          <DeletedSecretsButton onClick={goToDeleted}>Deleted Secrets</DeletedSecretsButton>
        ) : null}
      </DropdownOptionsWrap>
    </DropdownWrap>
  );
};

export default AdminDropdown;
