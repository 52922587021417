import React, { Fragment, useState, useEffect } from 'react';
import { NextButton, InfoInput, Fieldset, InfoText, Heading, Subheading } from './styles/ComponentStyles';
import { getValidation } from '../../utils/validationSuite/validationIndex';
import { useNotification } from '../../common/notification/Notification';
import CheckboxSwitch from '../../common/checkboxes/CheckboxSwitch';
import { ValidationHeader } from './ValidationHeader';
export const InitialDetails = ({ setDisplay, setData, data }) => {
  const { companyName, userName, userEmail, invited, invitedEmail } = data;
  const [company, setCompany] = useState(companyName ? companyName : '');
  const [fullname, setFullname] = useState(userName ? userName : '');
  const [email, setEmail] = useState(userEmail ? userEmail : '');
  const [enabled, setEnabled] = useState(false);
  const [termsOfService, setTermsOfService] = useState(false);
  const [marketingOptIn, setMarketingOptIn] = useState(false);

  const { notificationText, setNotificationText } = useNotification();

  useEffect(() => {
    company !== '' && email !== '' && fullname !== '' && termsOfService ? setEnabled(true) : setEnabled(false);
  }, [company, email, fullname]);

  const handleClick = () => {
    if (!enabled || !termsOfService) return;
    const params = {
      data: { company, fullname, email, termsOfService, marketingOptIn },
      setNotificationText,
    };
    setData({ ...data, companyName: company, userName: fullname, userEmail: email, termsOfService, marketingOptIn });
    if (getValidation('registerInitial')(params)) setDisplay('password');
  };
  const setCheckBox = event => {
    if (event === 'termsOfService') {
      setTermsOfService(!termsOfService);
      if (company !== '' && email !== '' && fullname !== '') {
        setEnabled(!enabled);
      }
    } else if (event === 'marketingOptIn') {
      setMarketingOptIn(!marketingOptIn);
    }
  };
  const renderFullName = () => (
    <Fieldset>
      {fullname !== '' ? <legend>Full Name</legend> : null}
      <InfoInput
        role={'InfoInput'}
        value={fullname}
        placeholder="What's your full name?"
        onChange={e => setFullname(e.target.value)}
      ></InfoInput>
    </Fieldset>
  );
  return (
    <Fragment>
      <ValidationHeader notificationText={notificationText} setNotificationText={setNotificationText} />
      <Heading role={'Heading'}>{invited ? `Hello ${userName}!` : 'Try Recrypt for free.'}</Heading>
      <Subheading role={'Subheading'}>
        {invited ? `You have been invited to join Recrypt by ${invitedEmail}.` : 'Your first 30 days are on us.'}
      </Subheading>
      <Fieldset>
        {company !== '' ? <legend>Company Name</legend> : null}
        <InfoInput
          role={'InfoInput'}
          value={company}
          placeholder="What's the name of your team?"
          onChange={e => setCompany(e.target.value)}
        ></InfoInput>
      </Fieldset>
      {!invited && renderFullName()}
      <Fieldset>
        {email !== '' ? <legend>Email Address</legend> : null}
        <InfoInput
          role={'InfoInput'}
          value={email}
          placeholder="What's your email address?"
          onChange={e => setEmail(e.target.value)}
        />
      </Fieldset>
      <InfoText style={{ marginBottom: -5, marginLeft: -5 }} role={'InfoText'}>
        <CheckboxSwitch
          id="toggleSwitch"
          defaultChecked={termsOfService}
          onChange={() => setCheckBox('termsOfService')}
          style={{ padding: 50 }}
        />
        &nbsp;I agree to Recrypt&nbsp;
        <a href={'https://recrypt.app/end-user-license-agreement'}>
          <b>End User Licence Agreement (EULA)</b>
        </a>
        &nbsp;and&nbsp;
        <a href={'https://recrypt.app/privacy-policy/'}>
          <b>Privacy Policy</b>
        </a>
      </InfoText>
      <InfoText style={{ marginLeft: -5 }} role={'InfoText'}>
        <CheckboxSwitch
          id="toggleSwitch"
          defaultChecked={marketingOptIn}
          onChange={() => setCheckBox('marketingOptIn')}
        ></CheckboxSwitch>
        &nbsp;Yes, please keep me updated on Recypt news, events and offers
      </InfoText>
      <NextButton role={'NextButton'} onClick={handleClick} className={enabled && 'enabled'}>
        Next
      </NextButton>
    </Fragment>
  );
};
export default InitialDetails;
