import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  children: ReactNode;
  coords?: DOMRect;
}

const Portal = ({ children, coords }: Props) => {
  const mount = document.getElementById('modal-root');
  const el = document.createElement('div');
  if (coords) el.style.cssText = `position: absolute; left: ${coords.x}px; top: ${coords.y}px`;

  if (!mount) return children;

  useEffect((): (() => void) => {
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el, mount]);

  return createPortal(children, el);
};

export default Portal;
