import React, { useContext, useState } from 'react';
import {
  SettingsWrap,
  SettingsSection,
  IconSetting,
  SettingsHeader,
  IconWrap,
  DividerLine,
} from './styles/UserSettingsStyles';
import { OptionsTitle, OptionsSubText, OptionWrapper } from '../../adminChests/styles/EditSecretsOptions';
import ToggleSwitch from '../../../../common/checkboxes/ToggleSwitch';
import UserApi from '../../../../api/users/UserApi';
import { ModalContext } from '../../../../context';
import { useNotification } from '../../../../common/notification/Notification';
import { resetPassword } from './subFunctions/userSettingsFunctions';
import SecretKeyApi from '../../../../api/secretKeys/SecretKeyApi';
import { ReshareSecret } from '../../../../utils/secretFunctions';
import { ReactComponent as Lock } from '../../../../images/recrypt_secret.svg';
import { useHistory } from 'react-router-dom';

export const UserSettings = ({ user, locked, permissions }) => {
  const { id, email } = user;
  const { setModal } = useContext(ModalContext);
  const { setNotificationText } = useNotification();
  const [blockUser, setBlockUser] = useState(locked);
  const { push } = useHistory();

  const handleSwitchToggle = () => {
    setBlockUser(!blockUser);
    if (!blockUser) {
      UserApi.lock(id);
    } else {
      UserApi.unlock(id);
    }
  };

  const resetUser = async input => {
    if (input.length < 12)
      return setNotificationText({
        type: 'error',
        text: 'Password must be at least 12 characters long.',
        display: 'inputModal',
      });
    await SecretKeyApi.purgeKeys({ id });
    const userInfo = await resetPassword(email, input);
    await UserApi.reset_password(id, userInfo);
    const reshareInfo = await SecretKeyApi.reshareUser({ id });
    const reshareJson = await ReshareSecret(reshareInfo.keyshare);
    SecretKeyApi.reshare(reshareJson);
    setModal();
  };

  const redirectTo = () =>
    push({
      pathname: '/admin/teams/user/edit',
      row: user,
    });

  const confirmModal = () =>
    setModal({
      key: 'Input',
      params: {
        title: 'Please set the new password for this user.',
        placeholder: 'New user password...',
        type: 'password',
        confirmAction: resetUser,
        cancelAction: () => setModal(),
      },
    });

  return (
    <SettingsWrap>
      <SettingsSection>
        <SettingsHeader>Settings</SettingsHeader>
        <IconSetting onClick={redirectTo}>
          <IconWrap>
            <i className={'fa fa-pen'} />
          </IconWrap>
          Edit User Info
        </IconSetting>
        <IconSetting onClick={confirmModal}>
          <IconWrap>
            <Lock />
          </IconWrap>
          Reset User Password
        </IconSetting>
      </SettingsSection>
      <DividerLine />
      <SettingsSection>
        <OptionWrapper>
          <OptionsTitle>BLOCK USER</OptionsTitle>
          <ToggleSwitch
            tabIndex="0"
            disabled={permissions === 'Admin'}
            onClick={handleSwitchToggle}
            checked={blockUser}
            id="block-user-toggle"
          />
        </OptionWrapper>
        <OptionsSubText>Blocking this user will prevent them from using Recrypt</OptionsSubText>
      </SettingsSection>
    </SettingsWrap>
  );
};
