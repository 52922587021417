import styled from 'styled-components';

export const DropdownButtonTitleStyle = styled.div`
  display: inline-flex;
  justify-content: space-between;
  flex: 6;
  background-color: inherit;
  color: inherit;
  height: 3rem;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;

  > div {
    line-height: 46px;
    flex: 3;
  }

  .sidebar-arrow {
    flex: 1;
    .up,
    .down {
      margin-left: -0.5rem;
    }
  }

  .sidebar-arrow > div > div.down::before {
    background-color: #ffffff;
  }

  .sidebar-arrow > div > div.down::after {
    background-color: #ffffff;
  }
`;

export const DropdownButtonText = styled.div`
  margin-left: 0rem;
  margin-right: 0.75rem;
  overflow: hidden;
`;
