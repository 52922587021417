import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../images/recrypt_logo_02_lightmode.svg';
import { LeftHeader, Header } from './styles/LayoutStyles';

export const RegisterHeader = () => {
  return (
    <Header>
      <LeftHeader>
        <Logo width="200px" />
      </LeftHeader>
      <Link to="/login" className="signInButton">
        SIGN IN
      </Link>
    </Header>
  );
};
