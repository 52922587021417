import styled from 'styled-components';

export const ModalTable = styled.table`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;

  tr {
    display: flex;
    align-items: center;
    text-align: left;
  }
`;

export const ModalTitle = styled.caption`
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 10px 0;
`;

export const ModalText = styled.td`
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  flex: 1;
  display: flex;

  svg {
    margin: 0 0.35rem;
  }
`;
