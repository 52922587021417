import React from 'react';
import { SecretHeaderContainer } from './styles/SecretHeaderStyles';
import { Icon } from '../../../common/Icon';

export const SecretHeader = ({ name }) => {
  return (
    <SecretHeaderContainer>
      <Icon name="unlocked" width="18px" />
      {`${name}`.slice(0, 40)}
    </SecretHeaderContainer>
  );
};
