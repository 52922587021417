import React, { useState, useContext, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { OptionsModalContext } from '../../../../../context/OptionsModalContext';
import { UpdateSecretData, DeleteSecret } from '../../../../../api/secrets/SecretApiFunctions';
import { IconArea } from './styles/BurgerMenuDropdownStyles';
import LogoutRedirect from '../../../../../common/nav/LogoutRedirect';
import { ModalContext } from '../../../../../context';
import { BurgerMenuDropdown } from './BurgerMenuDropdown';
import {
  DashboardContext,
  DashboardChestContext,
  DashboardViewChestContext,
} from '../../../../../context/DashboardContext';
import { GetChestSecrets, ManagerChestData, ChestTableData } from '../../../../../api/chests/ChestApiFunctions';
import { UserContext } from '../../../../../context/UserProvider';

export const ManagerChestActions = ({ row, highlightedRow }) => {
  const { setModal } = useContext(ModalContext);
  const [chests, setChests] = useState([]);
  const [optionsVisible, setOptions] = useContext(OptionsModalContext);
  const [highlightedOption, setHighlightedOption] = useState();
  const { user } = useContext(UserContext);
  const dashboard = useContext(DashboardContext);
  const setChestSecrets = useContext(DashboardViewChestContext).chestSecrets[1];
  const chestContext = useContext(DashboardChestContext);
  const setTableData = chestContext.tableData[1];
  const setEditSecret = dashboard.editSecret[1];

  useEffect(() => {
    let isMounted = true;
    ManagerChestData(user.id).then(result => {
      const permittedChests = result.filter(chest => chest.manager || user.administrator);
      if (isMounted) setChests(permittedChests);
    });
    return () => (isMounted = false);
  }, [user]);

  const getMenuOptions = () => {
    return [
      {
        text: 'Edit Secret',
        handleClick: () => editSecret(),
        styleId: 'secret-edit',
      },
      {
        text: 'Move to another Chest',
        handleClick: () => movePopUp(),
        styleId: 'secret-move',
      },
      {
        text: 'Remove',
        handleClick: () => deletePopUp(),
        styleId: 'secret-delete',
      },
    ];
  };

  const menuOptions = getMenuOptions();

  const refreshTable = async () => {
    setChestSecrets(await GetChestSecrets(row['chest'].id, user.id));
    setTableData(
      await ChestTableData({
        filter: chestContext.searchText,
        limit: chestContext.jsonLimit,
        offset: 0,
      }),
    );
  };

  const editSecret = () => {
    setOptions({});
    setEditSecret(row);
  };

  const handleMoveModalConfirm = (secret, currChest) =>
    UpdateSecretData(secret.id, {
      secret: { vault_id: currChest.id },
    }).then(() => {
      setModal();
      refreshTable();
    });

  const handleDeleteModalConfirm = () => {
    DeleteSecret(row.id).then(() => {
      refreshTable();
      setModal();
    });
  };

  const movePopUp = () => {
    const moveChests = chests.filter(chest => chest.id !== row.chest.id);
    setModal({
      key: 'Select',
      params: {
        action: 'Move',
        name: `${row['name']}`,
        row: row,
        subtitle: 'Select Chest',
        objList: moveChests,
        confirmAction: handleMoveModalConfirm,
        cancelAction: () => setModal(),
        escape,
      },
    });
  };

  const deletePopUp = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to delete ${row['name']}?`,
        subtext: 'This secret will be moved to "Deleted Secrets".',
        confirmText: `Yes, delete ${row['name']}`,
        cancelText: `No, keep ${row['name']}`,
        confirmAction: handleDeleteModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  const handleOptions = (row, e) => {
    setHighlightedOption('');
    optionsVisible.row !== row.id ? setOptions({ row: row.id, above: calculateOptionsPosition(e) }) : setOptions({});
  };

  const calculateOptionsPosition = e => {
    return e.clientY > window.innerHeight * 0.6 || window.innerHeight - e.clientY < 250 ? true : false;
  };

  const handleMouseLeave = () => {
    setOptions({});
    setHighlightedOption('');
  };

  // useEffect for keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('right', e => handleOptions(highlightedRow, e));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('right');
    };
  });
  if (!chests) return <LogoutRedirect />;
  return (
    <div style={{ position: 'relative' }} onMouseLeave={() => handleMouseLeave()}>
      <IconArea role={'Icon'} onClick={e => handleOptions(row, e)} className="icon-area">
        <i className="fas fa-bars"></i>
      </IconArea>
      {optionsVisible.row === row.id && (
        <BurgerMenuDropdown
          menuOptions={menuOptions}
          highlightedOption={highlightedOption}
          setHighlightedOption={setHighlightedOption}
        />
      )}
    </div>
  );
};
