export const getHeaders = context => keyHeaderPairs[context] || [];

const _secretHeaders = () => ({
  Secret: 'name',
  Type: 'type',
  Chest: 'adminChest',
  Team: 'teams',
  Strength: 'adminStrength',
  AdminActions: 'adminSecretActions',
});

// Name should be changed to 'Chest'
const _chestHeaders = () => ({
  Name: 'name',
  'No. Secrets': 'secrets',
  'Linked Teams': 'teams',
  Added: 'added',
  Updated: 'updated',
  Actions: 'adminChestActions',
});

// Name should be changed to 'Team'
const _teamHeaders = () => ({
  Team: 'name',
  'No. Users': 'users',
  'Linked Chests': 'chests',
  Added: 'added',
  Updated: 'updated',
  AdminActions: 'adminTeamActions',
});

const _userHeaders = () => ({
  User: 'name',
  Email: 'email',
  Team: 'teams',
  Permissions: 'permissions',
  AdminActions: 'adminUserActions',
});

const _deletedSecretsHeaders = () => ({
  Secret: 'name',
  Type: 'type',
  Chest: 'chest',
  Teams: 'teams',
  Strength: 'adminStrength',
  AdminActions: 'adminDeletedSecretActions',
});

const keyHeaderPairs = {
  Secrets: _secretHeaders,
  Chests: _chestHeaders,
  Teams: _teamHeaders,
  Users: _userHeaders,
  DeletedSecrets: _deletedSecretsHeaders,
};
