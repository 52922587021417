import React, { useState, useContext, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { OptionsModalContext } from '../../../../../context/OptionsModalContext';
import { useHistory } from 'react-router-dom';
import { ChestTableData, GetChestSecrets } from '../../../../../api/chests/ChestApiFunctions';
import { UpdateSecretData, DeleteSecret, SecretTableData } from '../../../../../api/secrets/SecretApiFunctions';
import { IconArea } from './styles/BurgerMenuDropdownStyles';
import LogoutRedirect from '../../../../../common/nav/LogoutRedirect';
import { AdminChestContext, AdminContext } from '../../../../../context/AdminContext';
import BaseApi from '../../../../../api/BaseApi';
import { ModalContext } from '../../../../../context';
import OrganisationApi from '../../../../../api/organisation/OrganisationApi';
import { BurgerMenuDropdown } from './BurgerMenuDropdown';
import { UserContext } from '../../../../../context/UserProvider';

const AdminSecretActions = ({ row, highlightedRow, deleted, params }) => {
  const modalContext = useContext(ModalContext);
  const setModal = modalContext && modalContext.setModal;
  const [chests, setChests] = useState([]);
  const [optionsVisible, setOptions] = useContext(OptionsModalContext);
  const [highlightedOption, setHighlightedOption] = useState();
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const { user } = useContext(UserContext);
  const [owner, setOwner] = useState(false);
  const adminContext = useContext(AdminContext);
  const setChestTags = adminContext && adminContext.chestTags[1];
  const setData = useContext(AdminChestContext).adminChestTable[1];
  useEffect(() => {
    let isMounted = true;
    OrganisationApi.show().then(result => {
      if (user.id === result.owner_id) if (isMounted) setOwner(true);
      ChestTableData({ everything: true }).then(result => {
        setChests(result);
      });
    });
    return () => (isMounted = false);
  }, [user]);

  const getMenuOptions = () => {
    if (deleted) {
      if (owner)
        return [
          {
            text: 'Restore',
            handleClick: () => restoreSecret(),
            styleId: 'deleted-secret-restore',
          },
          {
            text: 'Delete Permanently',
            handleClick: () => ownerDeletePopUp(),
            styleId: 'deleted-secret-destroy',
          },
        ];

      if (!owner)
        return [
          {
            text: 'Restore',
            handleClick: () => restoreSecret(),
            styleId: 'deleted-secret-restore',
          },
        ];
    }
    return [
      {
        text: 'Move to another Chest',
        handleClick: () => movePopUp(),
        styleId: 'secret-move',
      },
      {
        text: 'Edit Secret',
        handleClick: () => editSecret(),
        styleId: 'secret-edit',
      },
      {
        text: 'Remove',
        handleClick: () => deletePopUp(),
        styleId: 'secret-delete',
      },
    ];
  };

  const menuOptions = getMenuOptions();

  const refreshTable = async () => {
    setChestTags(await ChestTableData({ everything: true, options: ['*'] }));
    if (pathname === '/admin/chests')
      setData(await SecretTableData({ limit: 20, offset: 0, everything: true, options: ['*'] }));
    if (pathname === '/admin/chests/show') setData(await GetChestSecrets(row['adminChest'].id, null, true));
    if (pathname === '/admin/chests/deleted') params.setLoading(true);
  };

  const editSecret = () => {
    setOptions({});
    history.push({
      pathname: '/admin/chests/secret/edit',
      row,
      prevPathname: pathname,
    });
  };

  const restoreSecret = () =>
    BaseApi.restore(row['id']).then(() => {
      refreshTable();
      setModal();
    });

  const ownerDeleteSecret = () =>
    BaseApi.destroy('secrets', row['id']).then(() => {
      refreshTable();
      setModal();
    });

  const handleMoveModalConfirm = (secret, currChest) =>
    UpdateSecretData(secret.id, {
      secret: { vault_id: currChest.id },
    }).then(() => {
      setModal();
      refreshTable();
    });

  const handleDeleteModalConfirm = () => {
    DeleteSecret(row.id).then(() => {
      refreshTable();
      setModal();
    });
  };

  const movePopUp = () =>
    setModal({
      key: 'Select',
      params: {
        action: 'Move',
        name: `${row['name']}`,
        row: row,
        subtitle: 'Select Chest',
        objList: chests,
        confirmAction: handleMoveModalConfirm,
        cancelAction: () => setModal(),
        escape,
      },
    });

  const deletePopUp = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to delete ${row['name']}?`,
        subtext: 'This secret will be moved to "Deleted Secrets".',
        confirmText: `Yes, delete ${row['name']}`,
        cancelText: `No, keep ${row['name']}`,
        confirmAction: handleDeleteModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  const ownerDeletePopUp = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to permanently delete ${row['name']}?`,
        subtext: 'This secret will be irrecoverable.',
        confirmText: `Yes, delete ${row['name']}`,
        cancelText: `No, keep ${row['name']}`,
        confirmAction: ownerDeleteSecret,
        cancelAction: () => setModal(),
      },
    });

  const handleOptions = (row, e) => {
    setHighlightedOption('');
    optionsVisible.row !== row?.id ? setOptions({ row: row?.id, above: calculateOptionsPosition(e) }) : setOptions({});
  };

  const calculateOptionsPosition = e => {
    return e.clientY > window.innerHeight * 0.6 || window.innerHeight - e.clientY < 250 ? true : false;
  };

  const handleMouseLeave = () => {
    setOptions({});
    setHighlightedOption('');
  };

  // useEffect for keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('right', e => handleOptions(highlightedRow, e));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('right');
    };
  });

  if (!chests) return <LogoutRedirect />;
  return (
    <div data-testid={'actions-wrapper'} style={{ position: 'relative' }} onMouseLeave={() => handleMouseLeave()}>
      <IconArea onClick={e => handleOptions(row, e)} data-testid={'icon'} className="icon-area">
        <i className="fas fa-bars"></i>
      </IconArea>
      {optionsVisible.row === row.id && (
        <BurgerMenuDropdown
          menuOptions={menuOptions}
          highlightedOption={highlightedOption}
          setHighlightedOption={setHighlightedOption}
        />
      )}
    </div>
  );
};

export default AdminSecretActions;
