import styled from 'styled-components';

export const InfoBox = styled.div`
  padding: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  max-width: 50%;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;

  .password-meter {
    margin-top: -1rem;
    padding-bottom: 1.5rem;
  }

  @media screen and (min-width: 1024px) {
    max-width: 75%;
  }
  @media screen and (max-width: 1023px) {
    max-width: 90%;
    padding: 2.5rem;
  }
`;

export const WebLink = styled.a`
  padding-right: 2rem;
  font-size: 0.9rem;
  color: #252a41;
`;

export const NextButton = styled.button`
  font-family: 'Lato';
  font-size: 1rem;
  background-color: #cbd0dc;
  color: #ffffff;
  position: relative;
  border-radius: 5px;
  outline: none;
  padding: 0.75rem 1.25rem;
  border: 0;
  transition: all 0.3s ease-in-out;

  &.enabled {
    cursor: pointer;
    background-color: #40dae1;
  }

  &.cursorDisabled {
    cursor: not-allowed;
  }
`;

export const InfoInput = styled.input`
  outline: none;
  border: none;
  font-size: 100%;
  width: 95%;
  color: #252a41;
`;

export const Fieldset = styled.fieldset`
  padding: 0;
  margin: 0 0 1.5rem 0;
  border: 0;
  position: relative;

  &:first-of-type {
    margin-top: 0.5rem;
  }
  &:last-of-type {
    margin-bottom: 0;
  }

  legend {
    color: #98a2bb;
    transform: translateY(-1px);
    font-size: 0.9rem;
    position: absolute;
    left: calc(0.75rem - 5px);
    top: -9px;
    background: #fff;
    padding: 0 5px;
    transition: all 0.15s ease-in-out;

    + input {
      padding-top: 1rem;
    }
  }

  input {
    border-radius: 5px;
    border: 1px solid #afb7ca;
    padding: 0.75rem;
    display: block;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.15s ease-in-out;

    &::-webkit-input-placeholder {
      color: #afb7ca;
      font-size: 0.9rem;
    }
    &::-moz-placeholder {
      color: #afb7ca;
      font-size: 0.9rem;
    }
    &:-ms-input-placeholder {
      color: #afb7ca;
      font-size: 0.9rem;
    }
    &:-moz-placeholder {
      color: #afb7ca;
      font-size: 0.9rem;
    }
  }

  i {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  &:focus-within {
    input {
      border-color: #40dae1;
    }

    legend {
      color: #40dae1;
    }
  }
`;

export const InfoText = styled.p`
  display: flex;
  color: #777777;
  font-size: 0.9rem;
  line-height: 140%;
  margin: 1.5rem 0;
`;

export const Heading = styled.p`
  color: #252a41;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
`;

export const Subheading = styled.p`
  color: #252a41;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 1.75rem;
  line-height: 140%;

  &.text-center {
    text-align: center;
  }
`;

export const TextLink = styled.div`
  color: #98a2bb;
  font-size: 0.9rem;
  cursor: pointer;
  align-self: center;
  margin-top: 1rem;
  transition: all 0.15s ease-in-out;
  text-transform: uppercase;
  &:hover {
    color: #000000;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const EmailLink = styled.a`
  border: 1px solid #d2d6e0;
  border-radius: 5px;
  min-width: 9rem;
  padding: 0.75rem 1rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: #252a41;
  transition: all 0.15s ease-in-out;

  svg {
    margin-right: 0.75rem;
  }

  &:hover {
    opacity: 0.7;
  }
`;
