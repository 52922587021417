import React from 'react';
import { Wrap, Esc } from './styles/EscapeStyles';
import { Icon } from '../../../Icon';
import { Divider } from '../../../divider/Divider';

const Escape = ({ icon = 'escape', cancelAction, escapeModal }) => {
  return (
    <Wrap escapeModal={escapeModal} onClick={cancelAction}>
      <Icon name={icon} width="24px" />
      <Divider margin={'0 1rem'} />
      <Esc>Esc</Esc>
    </Wrap>
  );
};

export default Escape;
