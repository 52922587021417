import React, { useEffect, useState } from 'react';
import { placeholderFunc } from '../../../utils/commonFunctions';
import { searchFunction } from '../../../utils/searchFunctions';
import { Icon } from '../../Icon';
import FooterTag from '../../links/FooterTag';
import ModalWrap from './ModalWrap';
import Mousetrap from 'mousetrap';
import { Footer, Header, Search, SearchWrap, SelectWrap } from './styles/LinkModalStyles';
import Escape from './subComponents/Escape';
import MultiSelect from './subComponents/MultiSelect';

const LinkModal = ({
  headerText = 'Placeholder',
  data = [],
  confirmAction = placeholderFunc,
  cancelAction = placeholderFunc,
  escape,
}) => {
  const [searchText, setSearchText] = useState('');
  const [selected, setSelected] = useState([]);
  const headers = ['name'];

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('shift+n', () => confirmAction(selected));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('shift+n');
    };
  });

  return (
    <ModalWrap modalStyle={'squared'} cancelAction={cancelAction}>
      {escape && <Escape cancelAction={cancelAction} />}
      <Header>{headerText}</Header>
      <SearchWrap>
        <Icon name={'tick'} width={'24px'} />
        <Search
          type={'text'}
          placeholder={'Search here...'}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          tabIndex="0"
          autoFocus
        />
      </SearchWrap>
      <SelectWrap>
        <MultiSelect data={searchFunction(headers, data, searchText)} selected={selected} setSelected={setSelected} />
      </SelectWrap>
      <Footer>
        <FooterTag
          onClick={() => confirmAction(selected)}
          fontIcon={'fas fa-plus-circle'}
          text="Link"
          keyText="Shift + "
          icon={<Icon name="key-n" width="18px" />}
        />
      </Footer>
    </ModalWrap>
  );
};

export default LinkModal;
