import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { WelcomeContext } from '../../context/index';
import { Icon } from '../../common/Icon';
import { ConfirmContainer, ConfirmButton, ConfirmButtonContainer, ConfirmTextContainer } from './styles/ConfirmStyle';
import { TwoStepSubText } from './styles/CommonStyles';
import useCycle from '../../utils/hooks/shortcuts/useCycle';
import { UserContext } from '../../context/UserProvider';

const TwoStepConfirm = ({ setState }) => {
  const { user, update } = useContext(UserContext);
  const { administrator } = user;
  const { welcome, setWelcome } = useContext(WelcomeContext) || {};
  const history = useHistory();
  useCycle('recovery');

  const handleSuccess = () => {
    update('otp_required_for_login', true);
    if (welcome) {
      if (administrator) {
        setWelcome({
          tutorial: welcome.tutorial,
          twofactor: true,
          secret: welcome.secret,
        });
        if (!welcome.tutorial || !welcome.secret) history.push('/welcome');
      } else {
        setWelcome({
          password: welcome.password,
          twofactor: true,
          tutorial: false,
        });
        history.push('/welcome');
      }
    }
    if (!welcome) history.push('/');
  };

  return (
    <ConfirmContainer>
      <ConfirmTextContainer>
        <Icon name="alert" width="50px" />
        <h2>Did you save your recovery codes?</h2>
        <TwoStepSubText>
          If you lose access to your phone, you won't be able to log in without a recovery code
        </TwoStepSubText>
      </ConfirmTextContainer>
      <ConfirmButtonContainer>
        <ConfirmButton className="success recovery" onClick={handleSuccess}>
          Yes
        </ConfirmButton>
        <ConfirmButton className="back recovery" onClick={() => setState('SUCCESS')}>
          Back
        </ConfirmButton>
      </ConfirmButtonContainer>
    </ConfirmContainer>
  );
};

export default TwoStepConfirm;
