import styled from 'styled-components';

export const PaymentForm = styled.div`
  background-color: ${props => props.theme.settingsBgColor};
  border-radius: 5px;
  padding: 1.25rem 0 1.25rem 1.25rem;

  @media (-webkit-device-pixel-ratio: 1.5) {
    margin-bottom: 1.5rem;
  }

  @media screen and (max-width: 767px) {
    padding: 1.25rem;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Input = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem 0 1rem 0;
  background-color: ${props => props.theme.formBgColor};
  color: ${props => props.theme.fieldTextColor};
  border: 1px solid ${props => props.theme.fieldBorderColor};
  border-radius: 5px;
  padding: 1rem;
  font-size: 0.9rem;

  &::placeholder {
    color: ${props => props.theme.placeholderColor};
  }
`;

export const Label = styled.div`
  font-size: 0.9rem;
  color: ${props => props.theme.textColor};

  &.hidden-label {
    visibility: hidden;
  }
`;

export const HalfWrapper = styled.div`
  width: 45%;
  width: calc(50% - 1.25rem);
  min-width: 250px;
  margin: 0 1.25rem 0 0;
`;

export const QuarterWrapper = styled.div`
  width: 22.5%;
  width: calc(25% - 1.25rem);
  min-width: 125px;
  margin: 0 1.25rem 0 0;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
  }
`;

export const FormBreak = styled.div`
  margin: 1.25rem 1.25rem 0 0;

  > div {
    font-size: 1rem;
    font-weight: 700;
  }
  hr {
    margin: 1.25rem 0;
    opacity: 0.3;
  }
`;

export const Button = styled.button`
  width: 100%;
  border: 1px solid ${props => props.theme.fieldBorderColor};
  border-radius: 5px;
  box-sizing: border-box;
  background-color: ${props => props.theme.fieldBorderColor};
  margin: 0.5rem 0 1rem 0;
  padding: 1rem;
  font-size: 0.9rem;
  font-weight: 700;
  outline: none;
  transition: all 0.3s ease-in-out;
  color: #ffffff;

  &:hover {
    opacity: 0.7;
  }

  &.enabled {
    background-color: ${props => props.theme.enabledButtonColor};
    border-color: ${props => props.theme.enabledButtonColor};
    color: #ffffff;
    cursor: pointer;
  }
`;

export const NotificationWrapper = styled.div`
  padding-right: 1.25rem;
  margin-bottom: 1.25rem;
  font-size: 1rem;
`;

export const CardElementWrap = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin: 0.5rem 0 1rem 0;
  background-color: ${props => props.theme.formBgColor};
  color: ${props => props.theme.fieldTextColor};
  border: 1px solid ${props => props.theme.fieldBorderColor};
  border-radius: 5px;
  padding: 1rem;
  font-size: 0.9rem;
`;
