import React, { useState, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import ModalWrap from './ModalWrap';
import Escape from './subComponents/Escape';
import { TitleWrap, Action, Name, SubTitle, Footer, Confirm, Shortcut } from './styles/SelectionModalStyles';
import BubbleSelection from './subComponents/BubbleSelection';
import { placeholderFunc } from '../../../utils/commonFunctions';
import { Icon } from '../../Icon';

const SelectionModal = ({
  action,
  name,
  row,
  subtitle,
  objList,
  confirmAction = placeholderFunc,
  cancelAction,
  escape,
}) => {
  const [selection, setSelection] = useState();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', cancelAction, 'keyup');
      Mousetrap.bind('shift+enter', () => confirmAction(row, selection));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc', 'keyup');
      Mousetrap.unbind('shift+enter');
    };
  });

  return (
    <ModalWrap modalStyle={'squared'} cancelAction={cancelAction} padType={'Footer'}>
      {escape && <Escape cancelAction={cancelAction} />}
      <TitleWrap>
        <Action>{action}</Action>
        <Name>{name}</Name>
      </TitleWrap>
      <SubTitle>{subtitle}</SubTitle>
      <BubbleSelection selection={selection} setSelection={setSelection} objList={objList} />
      <Footer>
        <Confirm onClick={() => confirmAction(row, selection)}>
          <i className="fas fa-check-circle"></i>
          <span id="action">Move</span>
        </Confirm>
        <Shortcut>
          <span>Shift + Enter</span>
          <Icon name="enter" width="16px" />
        </Shortcut>
      </Footer>
    </ModalWrap>
  );
};

export default SelectionModal;
