import React from 'react';
import { ArrowWrap, ArrowStyle } from './styles/ArrowStyles';

const arrowPos = arrowState => (arrowState ? 'up' : 'down');

export const Arrow = ({ arrowState }) => (
  <ArrowWrap>
    <ArrowStyle role={'Arrow'} className={arrowPos(arrowState)} />
  </ArrowWrap>
);
