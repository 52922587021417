import React, { useState } from 'react';
import { TwoStepWrap, TwoStepContainer } from './styles/TwoStepStyle';
import TwoStepChoice from './TwoStepChoice';
import TwoStepCode from './TwoStepCode';
import TwoStepSuccess from './TwoStepSuccess';
import TwoStepRecover from './TwoStepRecover';
import TwoStepConfirm from './TwoStepConfirm';

const TwoStep = () => {
  const [stepState, setStepState] = useState('CHOICE');

  const renderTwoStepStage = () => {
    if (stepState === 'CHOICE') return <TwoStepChoice setState={setStepState} />;
    if (stepState === 'CODE') return <TwoStepCode setState={setStepState} />;
    if (stepState === 'SUCCESS') return <TwoStepSuccess />;
    if (stepState === 'CONFIRM') return <TwoStepConfirm setState={setStepState} />;
  };

  return (
    <TwoStepWrap>
      <TwoStepContainer className={stepState === 'SUCCESS' ? 'success' : ''}>{renderTwoStepStage()}</TwoStepContainer>
      {stepState === 'SUCCESS' && (
        <TwoStepContainer className="recover">
          <TwoStepRecover setState={setStepState} />
        </TwoStepContainer>
      )}
    </TwoStepWrap>
  );
};

export default TwoStep;
