import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SettingHR, SettingTitle } from '../../../common/settings/styles/SettingStyles';
import { ExportText, DecryptAll, OrganiseSecrets } from '../adminFunctions/DownloadSecrets';
import Setting from '../../../common/settings/Setting';
import OrganisationApi from '../../../api/organisation/OrganisationApi';
import BaseApi from '../../../api/BaseApi';
import { filterAllButAdmins } from '../adminFunctions/pageFunctions';
import { useNotification } from '../../../common/notification/Notification';
import { ModalContext } from '../../../context';
import { UserContext } from '../../../context/UserProvider';

const OwnerActionsSettings = () => {
  const history = useHistory();
  const { setModal } = useContext(ModalContext);
  const [activeSetting, setActiveSetting] = useState('');
  const [adminList, setAdminList] = useState(null);
  const [orgName, setOrgName] = useState('');
  const { setNotificationText } = useNotification();
  const { logout } = useContext(UserContext);

  useEffect(() => {
    let isMounted = true;
    OrganisationApi.show().then(result => {
      if (isMounted) setOrgName(result.name);
    });
    BaseApi.all('users').then(result => {
      if (isMounted) setAdminList(filterAllButAdmins(result['items']));
    });
    return () => (isMounted = false);
  }, []);

  const editOrgName = () => {
    const params = {
      organisation: {
        name: orgName,
      },
    };
    OrganisationApi.update(params).then(() => setNotificationText('Organisation name successfully changed.'));
  };

  const handleOrgDeletion = () => {
    OrganisationApi.delete().then(res => {
      if (res['error'] === undefined) {
        logout();
        setNotificationText('Organisation deleted successfully.');
        setModal();
      } else {
        setNotificationText('Organisation deletion failed.');
      }
    });
  };

  const setLoadingText = str => setModal({ ...modalObj, params: { ...modalObj.params, loadingText: str } });

  const downloadSecrets = async () => {
    setLoadingText('Retrieving Secrets');
    const secretData = await BaseApi.all('secrets', {
      everything: true,
      options: ['vault'],
    });
    setLoadingText('Retrieving Chests');
    const chestData = await BaseApi.all('vaults', { everything: true });
    const chests = chestData.items;
    const secrets = secretData.items;
    setLoadingText('Decrypting Secrets');
    const results = await Promise.all(DecryptAll(secrets));
    const data = OrganiseSecrets(results, secrets, chests);
    setLoadingText('Exporting');
    ExportText(data);
    setModal();
  };

  const modalObj = {
    key: 'SecureConfirm',
    params: {
      title: 'Are you sure you want to download all secrets?',
      placeholder: 'Enter your password...',
      confirmAction: downloadSecrets,
      cancelAction: () => setModal(),
    },
  };

  const downloadSecretsModal = () => setModal(modalObj);

  const deleteOrg = () =>
    setModal({
      key: 'SecureConfirm',
      params: {
        title: 'Are you sure you want to delete the organisation?',
        placeholder: 'Enter your password...',
        confirmAction: () => handleOrgDeletion(),
        cancelAction: () => setModal(),
      },
    });

  const handleOrgTransfer = selectedAdmin => {
    if (selectedAdmin === undefined) return;
    OrganisationApi.transfer({ owner_id: selectedAdmin['id'] })
      .then(res => {
        if (res['error'] === undefined) {
          history.push({
            pathname: '/',
            prevPathname: history.location.pathname,
          });
          setNotificationText('Organisation successfully transferred.');
        } else {
          setNotificationText(`Error: ${res['error']}`);
        }
      })
      .then(() => setModal());
  };

  const transferOrg = () =>
    setModal({
      key: 'Transfer',
      params: {
        title: 'Transfer Organisation',
        placeholder: 'Confirm Password',
        adminList: adminList,
        confirmAction: handleOrgTransfer,
        cancelAction: () => setModal(),
      },
    });

  return (
    <div>
      <SettingTitle>Owner Actions</SettingTitle>
      <SettingHR />
      <Setting
        name="Your Organisation Name"
        type="input"
        activeSetting={activeSetting}
        setActiveSetting={setActiveSetting}
        text={orgName}
        setText={setOrgName}
        buttonName="Edit"
        onClick={editOrgName}
      />
      <SettingHR />
      <Setting
        name="Download All Secrets"
        type="text"
        activeSetting={activeSetting}
        setActiveSetting={setActiveSetting}
        text="Decrypt all secrets currently stored within your chests and
          download them in plain-text format."
        buttonName="Download All Secrets"
        onClick={downloadSecretsModal}
      />
      <SettingHR />
      <Setting
        name="Transfer Ownership"
        type="text"
        activeSetting={activeSetting}
        setActiveSetting={setActiveSetting}
        text="Transfer ownership of the organisation to another Administrator-type user."
        buttonName="Transfer Ownership"
        onClick={transferOrg}
      />
      <SettingHR />
      <Setting
        name="Delete Organisation"
        type="text"
        activeSetting={activeSetting}
        setActiveSetting={setActiveSetting}
        text="Completely erase this organisation, including all users, chests,
          and secrets associated with it."
        buttonName="Delete Organisation"
        onClick={deleteOrg}
      />
    </div>
  );
};

export default OwnerActionsSettings;
