import React, { useContext, useState, Fragment } from 'react';
import { IconWrap, ButtonWrap, Confirm, Cancel } from './styles/ConfirmationModalStyles';
import { Icon } from '../../Icon';
import { placeholderFunc } from '../../../utils/commonFunctions';
import ModalWrap from './ModalWrap';
import Escape from './subComponents/Escape';
import { Error, LoadingText, PassInput } from './styles/SecureConfirmationModalStyles';
import { CheckPassword } from '../../../utils/AccountFunctions';
import { Title } from './styles/GenericStyles';
import GenericLoading from '../../genericLoading/GenericLoading';
import { UserContext } from '../../../context/UserProvider';

const SecureConfirmationModal = ({
  icon = 'alert',
  title,
  placeholder,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmAction = placeholderFunc,
  cancelAction = placeholderFunc,
  escape,
  loadingText,
}) => {
  const [pass, setPass] = useState('');
  const [error, setError] = useState('errorPlaceholder');
  const { user } = useContext(UserContext);
  const { email } = user;

  const handleConfirm = async () => {
    const passMatched = await CheckPassword(email, pass);
    if (passMatched) await confirmAction();
    if (!passMatched) setError('Password entered is incorrect.');
  };

  return (
    <ModalWrap cancelAction={cancelAction}>
      {escape && <Escape cancelAction={cancelAction} />}
      {loadingText ? (
        <Fragment>
          <GenericLoading />
          <LoadingText>{loadingText}</LoadingText>
        </Fragment>
      ) : (
        <Fragment>
          <IconWrap>
            <Icon name={icon} width={'36px'} />
          </IconWrap>
          <Title>{title}</Title>
          <PassInput type={'password'} placeholder={placeholder} value={pass} onChange={e => setPass(e.target.value)} />
          <Error visible={error !== 'errorPlaceholder'}>{error}</Error>
          <ButtonWrap>
            <Confirm onClick={handleConfirm} tabIndex="0" id="confirm-button">
              {confirmText}
            </Confirm>
            <Cancel onClick={cancelAction} tabIndex="0" id="cancel-button">
              {cancelText}
            </Cancel>
          </ButtonWrap>
        </Fragment>
      )}
    </ModalWrap>
  );
};

export default SecureConfirmationModal;
