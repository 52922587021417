import React, { useContext, useState, useEffect } from 'react';
import { WelcomeContext } from '../context/index';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import WelcomeRoutes from '../components/nav/WelcomeRoutes';
import ProfileApi from '../api/profiles/ProfileApi';
import { UserContext } from '../context/UserProvider';

const Welcome = () => {
  const [welcome, setWelcome] = useState({
    tutorial: false,
    twofactor: false,
    secret: false,
  });
  const { user, update } = useContext(UserContext);
  const { first_time } = user;
  const email = user.email;
  const hasSecret = user.has_secret;
  const isAdmin = user.administrator;
  const { state } = useLocation();
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;
    const welcomeCheck = async () => {
      if (isMounted) {
        if (isAdmin) {
          setWelcome(prevState => ({ ...prevState, twofactor: true }));
          if (hasSecret) setWelcome(prevState => ({ ...prevState, secret: true }));
        } else {
          if (!user['otp_required_for_login']) setWelcome({ password: false, twofactor: false, tutorial: false });
          if (user['otp_required_for_login']) setWelcome({ password: false, tutorial: false, twofactor: true });
        }
      }
    };
    welcomeCheck();
    return () => (isMounted = false);
  }, [email, hasSecret, isAdmin, state, history]);

  useEffect(() => {
    let isMounted = true;
    const welcomeFinish = async () => {
      if (Object.values(welcome).every(item => item)) {
        await ProfileApi.update({
          user: { first_time: false },
        });
        if (isMounted) update('first_time', false);
      }
    };
    welcomeFinish();
    return () => (isMounted = false);
  }, [welcome, update, state]);

  if (!first_time) return <Redirect to={{ pathname: '/' }} />;

  return (
    <WelcomeContext.Provider value={{ welcome, setWelcome }}>
      <WelcomeRoutes />
    </WelcomeContext.Provider>
  );
};

export default Welcome;
