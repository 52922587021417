import React from 'react';
import { reverseOne } from '../../../utils/arrayFunctions';
import { placeholderFunc } from '../../../utils/commonFunctions';
import { SectionTableWrapper } from '../styles/AdminFormStyle';
import { CellName, CellPermissions, CellX, TableRow } from '../styles/TeamFormStyle';
import { PermissionsCell } from './PermissionsCell';

const LinkFormSection = ({ selectedArr = [], setSelectedArr = placeholderFunc, name = 'Placeholder Name' }) => {
  const addedObj = arr => arr.filter(obj => obj.added === true);
  const removeObj = obj => setSelectedArr(reverseOne(selectedArr, obj, 'added'));
  const setPermission = (obj, perm) =>
    setSelectedArr(selectedArr.map(opt => (obj === opt ? { ...obj, edit_access: perm } : opt)));
  return (
    <SectionTableWrapper>
      <TableRow className="header-row">
        <CellName>{name}</CellName>
        <CellPermissions>{`${name} permissions`}</CellPermissions>
        <CellX></CellX>
      </TableRow>
      {selectedArr &&
        addedObj(selectedArr).map(obj => (
          <TableRow key={obj.id} className="highlight">
            <CellName>{obj.name}</CellName>
            <CellPermissions>
              <PermissionsCell object={obj} handlePermissions={setPermission} />
            </CellPermissions>
            <CellX
              onClick={() => removeObj(obj)}
              tabIndex="0"
              role={'cellX'}
              onKeyPress={e => (e.key === 'Enter' ? removeObj(obj) : null)}
            >
              <i className="fas fa-times"></i>
            </CellX>
          </TableRow>
        ))}
    </SectionTableWrapper>
  );
};

export default LinkFormSection;
