import React from 'react';
import PasswordMeter from '../../../../common/forms/PasswordMeter';
import { ChestSecretActions } from './subComponents/ChestSecretActions';
import { MySecretActions } from './subComponents/MySecretActions';
import TableButtonBar from '../../../../common/buttons/TableButtonBar';
import AdminSecretActions from './subComponents/AdminSecretActions';
import { AdminChestActions } from './subComponents/AdminChestActions';
import { AdminTeamActions } from './subComponents/AdminTeamActions';
import { AdminUserActions } from './subComponents/AdminUserActions';
import { BillingAccountsActions } from './subComponents/BillingAccountsActions';
import { UserPermissionsCell } from './subComponents/UserPermissionsCell';
import { BillingHistoryActions } from './subComponents/BillingHistoryActions';
import EmailCell from './subComponents/styles/EmailCell';
import LockCell from './subComponents/LockCell';
import { TableTd, MenuTd } from './styles/UniqueCellsStyles';
import { capitalize, formatDate } from '../../../../utils/commonFunctions';
import { ManagerChestActions } from './subComponents/ManagerChestActions';

export const getCell = key => keyCellPairs[key] || _defaultCell;

const _passwordStrengthCell = (key, row, _, onClick, idx, cellWidth) => (
  <TableTd cellWidth={cellWidth} key={idx}>
    <PasswordMeter strength={row[key]} onClick={_funcToApply(key, row, onClick)} />
  </TableTd>
);

const _adminPasswordStrengthCell = (key, row, _, onClick, idx, cellWidth) => (
  <TableTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <PasswordMeter strength={row['strength']} />
  </TableTd>
);

const _mySecretActionsCell = (key, row, params, onClick, idx, cellWidth) => (
  <TableTd cellWidth={cellWidth} key={idx}>
    <MySecretActions row={row} params={params} onClick={_funcToApply('nahfam', row, onClick)} />
  </TableTd>
);

const _secretActionsCell = (key, row, _, onClick, idx, cellWidth) => (
  <TableTd cellWidth={cellWidth} key={idx}>
    <ChestSecretActions type="secret" onClick={_funcToApply('', row, onClick)} />
  </TableTd>
);
const _chestActionsCell = (key, row, _, onClick, idx, cellWidth) => (
  <TableTd cellWidth={cellWidth} key={idx} onClick={() => onClick(row)}>
    <ChestSecretActions type="chest" />
  </TableTd>
);

const _managerChestActionsCell = (key, row, _, onClick, idx, cellWidth, highlightedRow) => (
  <MenuTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <ManagerChestActions row={row} highlightedRow={highlightedRow} />
  </MenuTd>
);

const _adminSecretActionsCell = (key, row, _, onClick, idx, cellWidth, highlightedRow) => (
  <MenuTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <AdminSecretActions row={row} highlightedRow={highlightedRow} />
  </MenuTd>
);

const _adminDeletedSecretActionsCell = (key, row, params, onClick, idx, cellWidth, highlightedRow) => (
  <MenuTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <AdminSecretActions row={row} highlightedRow={highlightedRow} deleted={true} params={params} />
  </MenuTd>
);

const _adminChestActionsCell = (key, row, _, onClick, idx, cellWidth, highlightedRow) => (
  <MenuTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <AdminChestActions row={row} highlightedRow={highlightedRow} />
  </MenuTd>
);

const _adminUserActionsCell = (key, row, _, onClick, idx, cellWidth, highlightedRow) => (
  <MenuTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <AdminUserActions row={row} highlightedRow={highlightedRow} />
  </MenuTd>
);

const _adminTeamActionsCell = (key, row, _, onClick, idx, cellWidth, highlightedRow) => (
  <MenuTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <AdminTeamActions row={row} highlightedRow={highlightedRow} />
  </MenuTd>
);

const _billingAccountsActionsCell = (key, row, params, onClick, idx, cellWidth) => (
  <MenuTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <BillingAccountsActions row={row} params={params} />
  </MenuTd>
);

const _dateCell = (key, row, _, onClick, idx, cellWidth) => (
  <TableTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    {`${formatDate(row[key])}`}
  </TableTd>
);

const _genericDateCell = (key, row, _, onClick, idx, cellWidth) => (
  <TableTd className="date" cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    {row[key]}
  </TableTd>
);

const _emailCell = (key, row, _, onClick, idx, cellWidth) => (
  <TableTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <EmailCell>{row[key]}</EmailCell>
  </TableTd>
);

const _lockCell = (key, row, _, onClick, idx, cellWidth) => (
  <TableTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <LockCell />
  </TableTd>
);

const _userPermissionsCell = (key, row, _, onClick, idx, cellWidth) => (
  <MenuTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <UserPermissionsCell row={row} />
  </MenuTd>
);

const _billingHistoryActionsCell = (key, row, _, onClick, idx, cellWidth) => (
  <MenuTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <BillingHistoryActions row={row} />
  </MenuTd>
);

const _chestTagsCell = (key, row, _, onClick, idx, cellWidth) => (
  <MenuTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    <TableButtonBar array={row[key]} tags={'chests'} />
  </MenuTd>
);

const _teamTagsCell = (key, row, _, onClick, idx, cellWidth) => (
  <MenuTd cellWidth={cellWidth} key={idx}>
    <TableButtonBar array={row[key]} tags={'teams'} onClick={_funcToApply('', row, onClick)} />
  </MenuTd>
);

const _objectCell = (key, row, _, onClick, idx, cellWidth) => (
  <TableTd role={'objectCell'} cellWidthcellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
    {`${row[key]['name']}`}
  </TableTd>
);

const _defaultCell = (key, row, _, onClick, idx, cellWidth) => {
  return (
    <TableTd role={'default-cell'} cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
      {`${capitalize(row[key])}`}
    </TableTd>
  );
};
const _secretChestCell = (key, row, _, onClick, idx, cellWidth) => {
  return (
    <TableTd cellWidth={cellWidth} key={idx} onClick={_funcToApply(key, row, onClick)}>
      <TableButtonBar array={[row[key]]} tags={'chests'} />
    </TableTd>
  );
};

const keyCellPairs = {
  strength: _passwordStrengthCell,
  adminStrength: _adminPasswordStrengthCell,
  mySecretActions: _mySecretActionsCell,
  secretActions: _secretActionsCell,
  managerChestActions: _managerChestActionsCell,
  adminSecretActions: _adminSecretActionsCell,
  adminChestActions: _adminChestActionsCell,
  adminUserActions: _adminUserActionsCell,
  adminTeamActions: _adminTeamActionsCell,
  adminDeletedSecretActions: _adminDeletedSecretActionsCell,
  billingHistoryActions: _billingHistoryActionsCell,
  chestActions: _chestActionsCell,
  billingAccountsActions: _billingAccountsActionsCell,
  chest: _objectCell,
  adminChest: _secretChestCell,
  created_at: _dateCell,
  added: _genericDateCell,
  updated: _genericDateCell,
  email: _emailCell,
  chests: _chestTagsCell,
  teams: _teamTagsCell,
  userPermissions: _userPermissionsCell,
  lock: _lockCell,
};

const _funcToApply = (key, row, onClick) => {
  if (key.includes('Actions')) return;
  if (key.includes('team')) return;
  return () => onClick(row);
};
