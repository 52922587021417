import styled from 'styled-components';

export const ProfileModalOverlay = styled.div`
  position: fixed;
  left: 0;
  top: -20vh;
  background-color: #000000;
  opacity: 0.5;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  z-index: 9;
`;

export const ProfileModalContainer = styled.div`
  background: ${props => props.theme.sButtonBgColor};
  color: ${props => props.theme.textColor};
  opacity: 1;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  max-height: 100%;
  width: 450px;
  border: none;
  border-radius: 5px;
  text-align: center;
  z-index: 10;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
`;

export const ProfileModalInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 3rem 3rem 3rem;
  background-color: inherit;
  cursor: pointer;
`;

export const ProfileModalTextArea = styled.div`
  margin-bottom: 2rem;
`;

export const ProfileModalTitle = styled.span`
  display: flex;
  justify-content: center;
  font-size: 2rem;

  &.success {
    margin-top: 1rem;
    color: ${props => props.theme.hoverRowBgColor};
    justify-content: center;
  }
`;

export const ProfileModalText = styled.span`
  display: flex;
  margin-top: 1rem;
`;

export const ProfileModalInputArea = styled.div`
  background-color: inherit;
`;

export const ProfileModalButtonArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfileSuccessIcon = styled.i`
  margin-top: 2rem;
  color: ${props => props.theme.hoverRowBgColor};
`;

export const ProfileModalButton = styled.button`
  background-color: ${props => props.theme.hoverRowBgColor};
  color: #ffffff;
  border: 1px solid ${props => props.theme.hoverRowBgColor};
  border-radius: 5px;
  font-size: 1rem;
  height: 3rem;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const ProfileBackLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  button {
    flex: 1;
    max-width: 48%;
    margin-left: 0;
    font-size: 0.9rem;
    font-weight: 700;
    &:first-of-type {
      background: ${props => props.theme.bgColor};
    }
    &:hover {
      background-color: ${props => props.theme.highlightColor};
    }
  }
`;
