import React, { useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { IconWrap, SubText, ButtonWrap, Confirm, Cancel } from './styles/ConfirmationModalStyles';
import { Icon } from '../../Icon';
import { focus } from '../../../utils/commonFunctions';
import ModalWrap from './ModalWrap';
import Escape from './subComponents/Escape';
import { Title } from './styles/GenericStyles';
import GenericLoading from '../../genericLoading/GenericLoading';

const ConfirmationModal = ({
  icon = 'alert',
  question,
  subtext,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmAction,
  cancelAction,
  escape,
  loading = false,
}) => {
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      focus('confirm-button');
      Mousetrap.bind('esc', cancelAction, 'keyup');
      Mousetrap.bind('enter', () =>
        document.activeElement === document.getElementById('cancel-button') ? cancelAction() : confirmAction(),
      );
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc', 'keyup');
      Mousetrap.unbind('enter');
    };
  });

  return (
    <ModalWrap cancelAction={cancelAction}>
      {escape && <Escape cancelAction={cancelAction} />}
      {loading ? (
        <GenericLoading />
      ) : (
        <IconWrap>
          <Icon name={icon} width={'36px'} />
        </IconWrap>
      )}
      <Title>{question}</Title>
      {subtext && <SubText>{subtext}</SubText>}
      <ButtonWrap>
        {confirmAction && (
          <Confirm onClick={confirmAction} tabIndex="0" id="confirm-button">
            {confirmText}
          </Confirm>
        )}
        {cancelAction && (
          <Cancel onClick={cancelAction} tabIndex="0" id="cancel-button">
            {cancelText}
          </Cancel>
        )}
      </ButtonWrap>
    </ModalWrap>
  );
};

export default ConfirmationModal;
