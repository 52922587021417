import React, { useState, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { CheckPassword } from '../../../utils/AccountFunctions';
import { ErrorMessage } from '../../../common/forms/styles/TextInputStyles';
import {
  ProfileModalInfoArea,
  ProfileModalInputArea,
  ProfileModalTextArea,
  ProfileModalTitle,
  ProfileModalText,
  ProfileModalButtonArea,
  ProfileModalButton,
  ProfileBackLink,
} from './styles/ProfileModalStyles';
import { TextInput } from '../../../common/forms/TextInput';
import { blur } from '../../../utils/commonFunctions';
import ChangePasswordSuccess from './ChangePasswordSuccess';

const ChangePassword = ({ title, email, handleConfirm, handleCancel }) => {
  const [passState, setPassState] = useState('PASSWORD');
  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [currentError, setCurrentError] = useState('');
  const [newError, setNewError] = useState('');
  const [matchError, setMatchError] = useState('');
  const [currentVisible, toggleCurrentVisibility] = useState(false);
  const [newVisible, toggleNewVisibility] = useState(false);
  const [confirmVisible, toggleConfirmVisibility] = useState(false);

  // Keyboard shortcuts
  const changePasswordMousetrap = new Mousetrap();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      changePasswordMousetrap.bind('esc', () => handleEscapeKey(document.activeElement.id), 'keyup');
      changePasswordMousetrap.bind('enter', submitNewPassword);
    }
    return () => {
      isMounted = false;
      changePasswordMousetrap.unbind('esc', 'keyup');
      changePasswordMousetrap.unbind('enter');
    };
  });

  const handleEscapeKey = id => {
    return id === 'current-password-input' || id === 'new-password-input' || id === 'confirm-password-input'
      ? blur(id)
      : handleCancel();
  };

  const submitNewPassword = async () => {
    setCurrentError('');
    setNewError('');
    setMatchError('');
    const result = await CheckPassword(email, currentPass);
    !result && setCurrentError('Incorrect Password');
    newPass.length < 12 && setNewError('New Password must be at least 12 characters');
    newPass !== confirmPass && setMatchError('Passwords must match');

    if (newPass !== confirmPass || newPass.length < 12 || !result) return;

    const apiRes = await handleConfirm({ oldPass: currentPass, newPass: confirmPass });
    if (!apiRes) setPassState('SUCCESS');
  };

  if (passState === 'SUCCESS') return <ChangePasswordSuccess />;

  return (
    <ProfileModalInfoArea>
      <ProfileModalTextArea>
        <ProfileModalTitle>{title}</ProfileModalTitle>
        <ProfileModalText>The password should be at least twelve characters long.</ProfileModalText>
      </ProfileModalTextArea>
      <ProfileModalInputArea>
        <TextInput
          className={currentError !== '' ? 'error' : ''}
          placeholder="Current Password"
          label="Current Password"
          type={currentVisible ? 'text' : 'password'}
          value={currentPass}
          handleChange={value => setCurrentPass(value)}
          id="current-password-input"
          rightIcon={currentVisible ? 'eyeclose' : 'eye'}
          rightIconClick={() => toggleCurrentVisibility(!currentVisible)}
          autoFocus
          mousetrap
        />
        <TextInput
          className={newError !== '' || matchError !== '' ? 'error' : ''}
          placeholder="New Password"
          label="New Password"
          type={newVisible ? 'text' : 'password'}
          value={newPass}
          handleChange={value => setNewPass(value)}
          id="new-password-input"
          rightIcon={newVisible ? 'eyeclose' : 'eye'}
          rightIconClick={() => toggleNewVisibility(!newVisible)}
          mousetrap
        />
        <TextInput
          className={matchError !== '' ? 'error' : ''}
          placeholder="Confirm Password"
          label="Confirm Password"
          type={confirmVisible ? 'text' : 'password'}
          value={confirmPass}
          handleChange={value => setConfirmPass(value)}
          id="confirm-password-input"
          rightIcon={confirmVisible ? 'eyeclose' : 'eye'}
          rightIconClick={() => toggleConfirmVisibility(!confirmVisible)}
          mousetrap
        />
        {currentError !== '' && <ErrorMessage>{currentError}</ErrorMessage>}
        {newError !== '' && <ErrorMessage>{newError}</ErrorMessage>}
        {matchError !== '' && <ErrorMessage>{matchError}</ErrorMessage>}
      </ProfileModalInputArea>
      <ProfileModalButtonArea>
        <ProfileModalButton
          className={currentPass === '' || newPass === '' || confirmPass === '' ? 'disable' : ''}
          onClick={submitNewPassword}
        >
          Save
        </ProfileModalButton>
        <ProfileBackLink onClick={handleCancel}>Back</ProfileBackLink>
      </ProfileModalButtonArea>
    </ProfileModalInfoArea>
  );
};

export default ChangePassword;
