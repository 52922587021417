import React, { Fragment, useState } from 'react';
import { Arrow } from '../arrow/Arrow';
import {
  FullWrapperFieldset,
  HalfWrapperFieldset,
  QuarterWrapperFieldset,
  Legend,
  InnerWrapper,
  Input,
  ErrorMessage,
} from './styles/TextInputStyles';
import { DropdownOptions } from './DropdownOptions';
import { DropdownChoiceTag } from './DropdownChoiceTag';
import { DropdownArrowWrapper, MultipleChoiceWrapper } from './styles/NewDropdownStyles';

// There should be a useEffect in the parent component which sets the options array
// If a default value is required, it should be set in the useEfect of the parent
// The options array may require having the following in the array:
//   { id: 'blank', value: '', label: '' },
// If setting select = true, this is required in the options array:
//   { id: 'custom', value: custom, label: custom }, where custom is a state set in the parent component
// If multiple=true, the parent component should handle array

// This renders different sized wrappers if desired
const Fieldset = ({ width, fieldSetProps, children }) => {
  switch (width) {
    case 'half':
      return (
        <HalfWrapperFieldset role={'half-wrapper-fieldset'} {...fieldSetProps}>
          {children}
        </HalfWrapperFieldset>
      );
    case 'quarter':
      return (
        <QuarterWrapperFieldset role={'quarter-wrapper-fieldset'} {...fieldSetProps}>
          {children}
        </QuarterWrapperFieldset>
      );
    default:
      return (
        <FullWrapperFieldset role={'full-wrapper-fieldset'} {...fieldSetProps}>
          {children}
        </FullWrapperFieldset>
      );
  }
};

export const NewDropdown = ({
  className,
  id,
  width,
  label,
  // Array of objects passed through from parent component: [{ id, value, label } etc]
  dropdownOptions,
  // These are passed through so that the parent component always knows whether the dropdown is open
  dropdownVisibility,
  setDropdownVisibility,
  // This should be known by the parent component, and should be set to blank if no default set
  optionSelected,
  // This isn't necessarily setOptionSelected (parent component), although it can be
  handleDropdownChange,
  error,
  // This should be true if wanting ability to type in / filter options
  select = false,
  // This should be used so parent component knows custom option (if select=true)
  setCustom,
  // This should be true if wanting ability to multiselect
  multiple = false,
  hideLegend = false,
}) => {
  const [customText, setCustomText] = useState('');
  const [choices, setChoices] = useState([]);

  // This renders the choice tags for multiple selections
  const renderChoices = () => {
    return choices.map(choice => (
      <DropdownChoiceTag role={'dropdown-choice-tag'} key={choice.id} choice={choice} remove={removeChoice} />
    ));
  };

  const removeChoice = id => {
    const newChoices = choices.filter(choice => choice.id !== id);
    setChoices(newChoices);
  };

  // This handles custom text input when select === true
  const handleCustom = text => {
    handleDropdownChange({ id: 'blank', value: '', label: '' });
    setCustomText(text);
  };

  // This is to handle custom options when dropdown loses focus
  const handleBlur = () => {
    if (customText !== '' && optionSelected.label === '') {
      setCustom(customText);
      handleDropdownChange({
        id: 'custom',
        value: customText,
        label: customText,
      });
    }
  };

  const renderInternals = () => (
    <Fragment>
      {!hideLegend && (
        <Legend
          className={
            (optionSelected.value === '' || optionSelected.value === undefined) &&
            choices.length === 0 &&
            customText === '' &&
            'empty'
          }
        >
          {label}
        </Legend>
      )}
      <InnerWrapper>
        {multiple ? (
          <MultipleChoiceWrapper role={'multiple-choice-wrapper'}>
            {choices.length > 0 ? renderChoices() : renderChoices()}
          </MultipleChoiceWrapper>
        ) : (
          <Input
            role={'internal-input'}
            placeholder={label}
            value={optionSelected.label !== '' ? optionSelected.label : customText}
            onChange={e => handleCustom(e.target.value)}
            readOnly={select ? false : true}
            onBlur={select ? () => handleBlur() : null}
            id={id}
            className="dropdown mousetrap"
          />
        )}
        <DropdownArrowWrapper>
          <Arrow arrowState={!dropdownVisibility} />
        </DropdownArrowWrapper>
      </InnerWrapper>
    </Fragment>
  );

  const renderDropdown = () => (
    <DropdownOptions
      dropdownOptions={dropdownOptions}
      handleDropdownChange={handleDropdownChange}
      choices={choices}
      setChoices={setChoices}
      customText={customText}
      setCustomText={setCustomText}
      setDropdownVisibility={setDropdownVisibility}
      multiple={multiple}
    />
  );

  const handleEnterPress = () => {
    setDropdownVisibility(!dropdownVisibility);
  };

  const fieldSetProps = {
    onMouseLeave: () => setDropdownVisibility(false),
    onClick: () => setDropdownVisibility(!dropdownVisibility),
    onKeyPress: e => (e.key === 'Enter' ? handleEnterPress() : null),
    onKeyDown: e => (e.key === 'Tab' ? setDropdownVisibility(false) : null),
    className: `dropdown ${dropdownVisibility && 'dropdown-opened'} ${className}`,
  };

  return (
    <>
      <Fieldset role={'field-set-container'} width={width} fieldSetProps={fieldSetProps}>
        {optionSelected && renderInternals()}
        {dropdownVisibility && renderDropdown()}
      </Fieldset>
      {error && <ErrorMessage role={'error-message'}>{error}</ErrorMessage>}
    </>
  );
};
