import styled from 'styled-components';

export const ArrowWrap = styled.div`
  display: inline-flex;
  pointer-events: none;
  justify-content: right;
  width: 1rem;

  .up {
    &::before {
      left: -3px;
      transform: rotate(-45deg);
    }

    &::after {
      left: 3px;
      transform: rotate(45deg);
    }
  }
`;

export const ArrowStyle = styled.div`
  position: relative;
  height: 10px;
  margin-left: 1rem;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: -0.05rem;
    width: 0.1rem;
    height: 100%;
    transition: all 0.3s;
    background-color: ${({ theme }) => theme.textColor};
  }

  &::before {
    left: -3px;
    transform: rotate(45deg);
  }

  &::after {
    left: 3px;
    transform: rotate(-45deg);
  }
`;
