import React from 'react';
import { FooterTagBar, FooterButton } from './styles/FooterLinkStyle';

const FooterTag = ({ onClick, fontIcon, text, keyText, icon }) => {
  return (
    <FooterTagBar role={'FooterTag'}>
      <FooterButton role={'FooterButton'} tabIndex="0" className="footer-button" onClick={onClick}>
        <i className={fontIcon} />
        {text}
      </FooterButton>
      <FooterButton role={'FooterButton'}>
        {keyText}
        {icon}
      </FooterButton>
    </FooterTagBar>
  );
};

export default FooterTag;
