export const getShortcuts = path => shortcutsSelector[path] || shortcutsSelector['/'];

const generic = [
  {
    text: 'Profile Settings',
    modifier: 'Shift + /',
    // The / should be an icon, not part of the modifier
  },
  {
    text: 'Log out',
    modifier: 'Shift + L',
    // The L should be an icon, not part of the modifier
  },
];

const dashboard = admin => [
  {
    text: 'Navigation',
    icon: 'arrows',
  },
  {
    text: 'Selection',
    modifier: 'Enter ',
    icon: 'enter',
  },
  {
    text: 'Open Dashboard',
    modifier: 'Shift + ',
    icon: 'keydown',
  },
  {
    text: 'Switch Secrets/Chests',
    modifier: 'Shift + ',
    icon: 'keyleft',
    iconTwo: 'keyright',
  },
  {
    text: 'Add a new secret',
    modifier: 'Shift + ',
    icon: 'key-n',
  },
  {
    text: 'Focus Searchbar',
    modifier: 'i',
    // This should be an icon, not a modifier
  },
  admin && {
    text: 'Go to Admin',
    modifier: 'Shift + A',
    // The A should be an icon, not part of the modifier
  },
  ...generic,
];

const adminChests = () => [
  {
    text: 'Select Rows',
    modifier: 'Tab or',
    icon: 'keyup',
    iconTwo: 'keydown',
  },
  {
    text: 'Selection',
    modifier: 'Enter ',
    icon: 'enter',
  },
  {
    text: 'Open Row Options',
    icon: 'keyright',
  },
  {
    text: 'Switch Chests/Secrets',
    modifier: 'Shift + ',
    icon: 'keyleft',
    iconTwo: 'keyright',
  },
  {
    text: 'Move to Teams',
    modifier: 'Shift + ',
    icon: 'keydown',
  },
  {
    text: 'Add new Chest / Secret',
    modifier: 'Shift + ',
    icon: 'key-n',
  },
  {
    text: 'Focus Searchbar',
    modifier: 'i',
    // This should be an icon, not a modifier
  },
  {
    text: 'Back to Recrypt',
    modifier: 'Shift + D',
    // The D should be an icon, not part of the modifier
  },
  ...generic,
];

const viewChest = () => [
  {
    text: 'Select Rows',
    modifier: 'Tab or',
    icon: 'keyup',
    iconTwo: 'keydown',
  },
  {
    text: 'Selection',
    modifier: 'Enter ',
    icon: 'enter',
  },
  {
    text: 'Open Row Options',
    icon: 'keyright',
  },
  {
    text: 'Switch Chests/Secrets',
    modifier: 'Shift + ',
    icon: 'keyleft',
    iconTwo: 'keyright',
  },
  {
    text: 'Move to Teams',
    modifier: 'Shift + ',
    icon: 'keydown',
  },
  {
    text: 'Add a new secret',
    modifier: 'Shift + ',
    icon: 'key-n',
  },
  {
    text: 'Focus Searchbar',
    modifier: 'i',
    // This should be an icon, not a modifier
  },
  {
    text: 'Back to Recrypt',
    modifier: 'Shift + D',
    // The D should be an icon, not part of the modifier
  },
  ...generic,
];

const viewSecret = () => [
  {
    text: 'Move to Teams',
    modifier: 'Shift + ',
    icon: 'keydown',
  },
  {
    text: 'Edit Secret',
    modifier: 'Shift + E',
    // the E should be an icon, not a modifier
  },
  {
    text: 'Go back / Close',
    icon: 'esc',
  },
  {
    text: 'Back to Recrypt',
    modifier: 'Shift + D',
    // The D should be an icon, not part of the modifier
  },
  ...generic,
];

const editOrNew = () => [
  {
    text: 'Select',
    modifier: 'Enter ',
    icon: 'enter',
  },
  {
    text: 'Save',
    modifier: 'Shift + ',
    icon: 'key-s',
  },
  {
    text: 'Go back / Cancel',
    icon: 'esc',
  },
  {
    text: 'Back to Recrypt',
    modifier: 'Shift + D',
    // The D should be an icon, not part of the modifier
  },
  ...generic,
];

const adminTeams = () => [
  {
    text: 'Select Rows',
    modifier: 'Tab or',
    icon: 'keyup',
    iconTwo: 'keydown',
  },
  {
    text: 'Selection',
    modifier: 'Enter ',
    icon: 'enter',
  },
  {
    text: 'Open Row Options',
    icon: 'keyright',
  },
  {
    text: 'Switch Teams/Users',
    modifier: 'Shift + ',
    icon: 'keyleft',
    iconTwo: 'keyright',
  },
  {
    text: 'Move to Chests',
    modifier: 'Shift + ',
    icon: 'keyup',
  },
  {
    text: 'Go to Admin Settings',
    modifier: 'Shift + ',
    icon: 'keydown',
  },
  {
    text: 'Add new Team / User',
    modifier: 'Shift + ',
    icon: 'key-n',
  },
  {
    text: 'Focus Searchbar',
    modifier: 'i',
    // This should be an icon, not a modifier
  },
  {
    text: 'Back to Recrypt',
    modifier: 'Shift + D',
    // The D should be an icon, not part of the modifier
  },
  ...generic,
];

const viewTeam = () => [
  {
    text: 'Select Rows',
    modifier: 'Tab or',
    icon: 'keyup',
    iconTwo: 'keydown',
  },
  {
    text: 'Selection',
    modifier: 'Enter ',
    icon: 'enter',
  },
  {
    text: 'Open Row Options',
    icon: 'keyright',
  },
  {
    text: 'Move to Chests',
    modifier: 'Shift + ',
    icon: 'keyup',
  },
  {
    text: 'Go to Admin Settings',
    modifier: 'Shift + ',
    icon: 'keydown',
  },
  {
    text: 'Add new User',
    modifier: 'Shift + ',
    icon: 'key-n',
  },
  {
    text: 'Focus Searchbar',
    modifier: 'i',
    // This should be an icon, not a modifier
  },
  {
    text: 'Back to Recrypt',
    modifier: 'Shift + D',
    // The D should be an icon, not part of the modifier
  },
  ...generic,
];

const viewUser = () => [
  {
    text: 'Selection',
    modifier: 'Enter ',
    icon: 'enter',
  },
  {
    text: 'Go back / Close',
    icon: 'esc',
  },
  {
    text: 'Move to Chests',
    modifier: 'Shift + ',
    icon: 'keyup',
  },
  {
    text: 'Go to Admin Settings',
    modifier: 'Shift + ',
    icon: 'keydown',
  },
  {
    text: 'Back to Recrypt',
    modifier: 'Shift + D',
    // The D should be an icon, not part of the modifier
  },
  ...generic,
];

const adminSettings = () => [
  {
    text: 'Navigate between settings',
    icon: 'keyup',
    iconTwo: 'keydown',
  },
  {
    text: 'Move to Teams',
    modifier: 'Shift + ',
    icon: 'keyup',
  },
  {
    text: 'Back to Recrypt',
    modifier: 'Shift + D',
    // The D should be an icon, not part of the modifier
  },
  ...generic,
];

const profile = () => [
  {
    text: 'Navigate between settings',
    modifier: 'Tab',
    // The Tab should be an icon, not part of the modifier
  },
  {
    text: 'Go back',
    icon: 'esc',
  },
];

const plans = () => [
  {
    text: 'Navigate between options',
    modifier: 'Tab',
    // The Tab should be an icon, not part of the modifier
  },
  {
    text: 'Go to Admin',
    modifier: 'Shift + A',
    // The A should be an icon, not part of the modifier
  },
  {
    text: 'Go back to Recrypt',
    modifier: 'Shift + D',
    // The D should be an icon, not part of the modifier
  },
  ...generic,
];

const shortcutsSelector = {
  '/': dashboard,
  '/admin/chests': adminChests,
  '/admin/chests/show': viewChest,
  '/admin/chests/secret': viewSecret,
  '/admin/chests/secret/edit': editOrNew,
  '/admin/chests/secret/new': editOrNew,
  '/admin/chests/new': editOrNew,
  '/admin/chests/edit': editOrNew,
  '/admin/teams': adminTeams,
  '/admin/teams/show': viewTeam,
  '/admin/teams/user': viewUser,
  '/admin/teams/user/new': editOrNew,
  '/admin/teams/user/edit': editOrNew,
  '/admin/teams/new': editOrNew,
  '/admin/teams/edit': editOrNew,
  '/admin/settings': adminSettings,
  '/profile': profile,
  '/plans': plans,
};
