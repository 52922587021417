import React, { useState } from 'react';
import { OptionsModalProvider } from '../../context/OptionsModalContext';
import TableHeader from './subComponents/TableHeader';
import TableBody from './subComponents/TableBody';
import NoResultFound from './subComponents/NoResultFound';
import { searchFunction } from '../../utils/searchFunctions';
import { sortTable } from '../../utils/sortFunctions';
import { determineColumnSort } from './subFunctions/tableFunctions';
import { searchableHeaders } from './subFunctions/headerFunctions';
import { placeholderFunc } from '../../utils/commonFunctions';
import TableLoading from './subComponents/TableLoading';
import { TableContainer } from './styles/TableStyles';

const Table = ({
  headers,
  columnWidths,
  data,
  searchText = '',
  searchable = [],
  setViewSecret,
  setViewChest,
  onClick = placeholderFunc,
  showPopular = false,
  params,
  noHeader,
  isAdmin,
  context,
}) => {
  const columnHeaders = Object.keys(headers);
  const jsonKeys = Object.values(headers);
  const [selectedHeader, setSelectedHeader] = useState({
    header: null,
    reverse: false,
  });
  const sortType = determineColumnSort(headers, selectedHeader, data);
  const searchHeaders = searchableHeaders(headers, searchable);
  const searchResults = searchFunction(searchHeaders, data, searchText, selectedHeader);
  const searchedData = sortTable(sortType)(headers[selectedHeader['header']], selectedHeader, searchResults);
  const showPopularModifier = () => {
    if (!showPopular) return false;
    if (data.length < 4) return false;
    if (searchText !== '') return false;
    if (selectedHeader['header'] !== null) return false;
    return showPopular;
  };

  const renderTable = () => {
    if (data.length === 0) return renderNoResult();
    if (params && params['loading']) return <TableLoading />;
    if (setViewChest) return returnChestsList();
    if (setViewSecret) return returnSecretsList();
    return genericTable();
  };
  const renderNoResult = () => (
    <NoResultFound setViewChest={setViewChest} setViewSecret={setViewSecret} context={context} />
  );
  const returnSecretsList = () => (
    <TableBody
      role={'secrets-list-table'}
      handleRow={setViewSecret}
      data={searchedData}
      jsonKeys={jsonKeys}
      showPopular={showPopularModifier()}
      params={params}
      columnWidths={columnWidths}
    />
  );

  const returnChestsList = () => (
    <TableBody
      role={'chests-list-table'}
      handleRow={setViewChest}
      data={searchedData}
      jsonKeys={jsonKeys}
      params={params}
      columnWidths={columnWidths}
    />
  );

  const genericTable = () => (
    <OptionsModalProvider>
      <TableBody
        role={'generic-table'}
        handleRow={onClick}
        data={searchedData}
        jsonKeys={jsonKeys}
        showPopular={showPopularModifier()}
        params={params}
        columnWidths={columnWidths}
        isAdmin={isAdmin}
        headers={headers}
      />
    </OptionsModalProvider>
  );

  return (
    <TableContainer>
      {!noHeader && (
        <TableHeader
          headers={columnHeaders}
          selectedHeader={selectedHeader}
          setSelectedHeader={setSelectedHeader}
          headerWidths={columnWidths}
        />
      )}
      {renderTable()}
    </TableContainer>
  );
};

export default Table;
