import styled from 'styled-components';

export const OptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 58vh;

  @media screen and (max-width: 767px) {
    max-height: unset;
  }
`;

export const SettingTitle = styled.h3`
  font-size: 1.6rem;
  margin-bottom: -0.1rem;
`;

export const SettingHR = styled.hr`
  width: 100%;
  color: ${props => props.theme.textColor};
  opacity: 0.2;
  margin: 1.25rem 0;
`;

export const SettingStyle = styled.div`
  margin-bottom: 2rem;
`;

export const SettingName = styled.p`
  font-size: 1.15rem;
  font-weight: bold;
`;

export const SettingArea = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const SettingInputArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const SettingInput = styled.input`
  background-color: inherit;
  font-family: 'Lato';
  border: none;
  color: ${props => props.theme.fieldTextColor};
  font-size: 0.9rem;
  border-radius: 5px;
  margin-left: 0;
  padding: 1rem 1.25rem;
  background-color: ${props => props.theme.bgColor};
  border: 2px solid ${props => props.theme.highlightColor};
  appearance: none;
  outline: none;

  &.error {
    border-color: ${props => props.theme.error};
  }

  ::placeholder {
    color: ${props => props.theme.placeholderColor};
  }

  &:focus {
    border-color: ${props => props.theme.highlightColor};
    outline: none;
  }

  &.error:focus {
    border-color: ${props => props.theme.error};
    outline: none;
  }

  &:focus ~ label {
    color: ${props => props.theme.highlightColor};
  }

  &:focus ~ i {
    visibility: visible;
    color: ${props => props.theme.highlightColor};
  }

  &:disabled {
    opacity: 0.5;
    border: none;
  }
`;

export const SettingText = styled.p`
  font-size: 0.9rem;
  line-height: 150%;
  width: 50%;
  flex: 3;
  width: 100%;
  margin-top: -0.5rem;
`;

export const SettingButton = styled.button`
  background-color: ${props => props.theme.settingsSelectColor};
  color: ${props => props.theme.hoverButtonColor};
  border: 2px solid ${props => props.theme.settingsSelectColor};
  border-radius: 5px;
  flex: 1.5;
  height: 3rem;
  margin-left: 3rem;
  max-height: 3rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  text-align: center;
  align-items: center;
  font-size: 1rem;
  outline: none;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    border: 2px solid ${props => props.theme.fieldTextColor};
  }

  &.disable {
    pointer-events: none;
    opacity: 0.5;
  }

  span {
    color: #808080;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 1.5rem 0 0 0;
    padding: 1.25rem;
  }
`;
