import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  StepSubContainer,
  TwoStepFooter,
  TwoStepText,
  SubTextContainer,
  TwoStepSubText,
  TwoStepButton,
  BackLink,
} from './styles/CommonStyles';
import { Icon } from '../../common/Icon';
import {
  CodeInputWrap,
  CodeInputLegend,
  CodeInput,
  CodeImageContainer,
  CodeImage,
  CodeError,
} from './styles/CodeStyle';
import TwoFactorApi from '../../api/twofactor/TwoFactorApi';
import LogoutRedirect from '../../common/nav/LogoutRedirect';
import useCycle from '../../utils/hooks/shortcuts/useCycle';
import { UserContext } from '../../context/UserProvider';

const TwoStepCode = ({ setState }) => {
  const { user } = useContext(UserContext);
  const { email } = user;
  const [redirect, setRedirect] = useState(false);
  const [codeInput, setCodeInput] = useState('');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [key, setKey] = useState('');
  useCycle('twoStep');

  const codeDetails = {
    email: `Recrypt (${email})`,
    key: key,
    time: 'Yes',
  };

  useEffect(() => {
    let isMounted = true;
    TwoFactorApi.show().then(async response => {
      if (isMounted && response) {
        setKey(response.key);
        const parse = new DOMParser();
        const docNode = parse.parseFromString(response.qr, 'image/svg+xml');
        const doc = docNode.childNodes[0];
        const svgElement = document.getElementById('svg');
        svgElement.innerHTML = doc.innerHTML;
        setImageLoaded(true);
      } else {
        if (isMounted) setRedirect(true);
      }
    });
    return () => (isMounted = false);
  }, []);

  if (redirect) return <LogoutRedirect />;

  const handleChange = e => setCodeInput(e.target.value);

  const sendOTP = event => {
    event.preventDefault();
    const code = {
      code: codeInput,
    };

    TwoFactorApi.enableOtp(code).then(async response => {
      if (response['2fa'] === 'Success: Correct OTP Code!') {
        setError(null);
        setState('SUCCESS');
      } else {
        setError(response['2fa']);
      }
    });
  };

  return (
    <StepSubContainer>
      <Fragment>
        <TwoStepText className="reduced-margin">
          <Icon name="asterisk" width="15px" />
          <span>Scan this QR code</span>
        </TwoStepText>
        <CodeImageContainer>
          <CodeImage className={imageLoaded ? 'resized' : ''} xmlns="http://www.w3.org/2000/svg" id="svg" />
        </CodeImageContainer>
        <SubTextContainer>
          <TwoStepSubText className="bold">Can't scan the code?</TwoStepSubText>
          <TwoStepSubText>You can add the code manually using the following details</TwoStepSubText>
        </SubTextContainer>
        <SubTextContainer>
          <TwoStepSubText>
            <b>Account:</b> {codeDetails.email}
          </TwoStepSubText>
        </SubTextContainer>
        <SubTextContainer>
          <TwoStepSubText>
            <b>Key:</b> {codeDetails.key}
          </TwoStepSubText>
        </SubTextContainer>
        <SubTextContainer>
          <TwoStepSubText>
            <b>Time based:</b> {codeDetails.time}
          </TwoStepSubText>
        </SubTextContainer>
        <TwoStepText className="reduced-margin">
          <Icon name="asterisk" width="15px" />
          <span>Enter the resulting verification code</span>
        </TwoStepText>
        <CodeInputWrap>
          <CodeInputLegend>Verification Code</CodeInputLegend>
          <CodeInput
            name="Verification Code"
            className={'twoStep'}
            role={'code-input'}
            type="text"
            value={codeInput}
            onChange={handleChange}
            onKeyPress={event => {
              codeInput && event.key === 'Enter' ? sendOTP(event) : null;
            }}
            autoFocus
          />
        </CodeInputWrap>
        {error !== null && <CodeError>{error}</CodeError>}
        <SubTextContainer>
          <TwoStepSubText>
            <b>Can't find your code?</b> <span>Get Help</span>
          </TwoStepSubText>
        </SubTextContainer>
      </Fragment>
      <TwoStepFooter>
        <TwoStepButton disabled={!codeInput} onClick={sendOTP} className={'twoStep'}>
          Enable two-step verification
        </TwoStepButton>
        <BackLink onClick={() => setState('CHOICE')} className={'twoStep'}>
          Back
        </BackLink>
      </TwoStepFooter>
    </StepSubContainer>
  );
};

export default TwoStepCode;
