export const searchFunction = (headers, data, searchText) => _searchAllColumns(headers, data, searchText);

const _searchAllColumns = (headers, data, searchText) => {
  const listOfResultsByColumn = Object.keys(headers).map(key => _searchColumn(headers[key], data, searchText));
  return _mergeAndMakeUnique(listOfResultsByColumn);
};

const _searchColumn = (header, data, searchText) =>
  data.filter(row => row[header] && `${row[header]}`.toUpperCase().includes(searchText.toUpperCase()));

const _mergeAndMakeUnique = results => {
  if (results === undefined) return null;
  return [...new Set([].concat(...results))];
};
