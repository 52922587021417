import styled from 'styled-components';

export const TableBottomLoading = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 50%;
  height: 20px;
  background-color: ${props => props.theme.tableBgColor};

  > img {
    height: 20px;
  }
`;
