import React from 'react';
import { Container, FieldLegend, TextArea } from './styles/NoteAreaStyles';

const NoteArea = ({ placeholder, label, value, onChange }) => {
  return (
    <Container>
      <TextArea role={'NoteArea'} className={'area'} placeholder={placeholder} value={value} onChange={onChange} />
      <FieldLegend className={'legend'}>{label}</FieldLegend>
    </Container>
  );
};

export default NoteArea;
