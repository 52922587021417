import React, { Fragment } from 'react';
import { TableTr } from '../styles/TableStyles';
import { getCell } from '../subComponents/uniqueCells/UniqueCells';

export const generateRows = (jsonKeys, data, onClick, onHighlight, params, columnWidths, highlight, role, headers) => {
  const tableRowOnClickStrategies = {
    'secrets-list-table': row => onClick(row),
    'chests-list-table': row => onClick(row),
  };

  return data.map((row, i) => {
    return (
      <Fragment key={i}>
        <TableTr
          className={'table-row'}
          role={'table-row'}
          onClick={() => {
            if (tableRowOnClickStrategies[role]) tableRowOnClickStrategies[role](row);
          }}
          onMouseEnter={() => onHighlight(i)}
          tabIndex="0"
          key={i}
          onFocus={() => onHighlight(i)}
          data-testid={`table-row`}
          headers={headers}
        >
          {_generateCells(jsonKeys, row, onClick, params, columnWidths, highlight)}
        </TableTr>
      </Fragment>
    );
  });
};

const _generateCells = (jsonKeys, row, onClick, params, columnWidths, highlight) =>
  jsonKeys.map((key, idx) => {
    const cellWidth = columnWidths[idx];
    const highlightedRow = highlight;
    return getCell(key)(key, row, params, onClick, idx, cellWidth, highlightedRow);
  });
