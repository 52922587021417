import styled from 'styled-components';

export const DropDownOptions = styled.div`
  position: absolute;
  display: block;
  top: 100%;
  left: -1px;
  border: 1px solid ${props => props.theme.highlightColor};
  border-radius: 0px 0px 5px 5px;
  background: ${props => props.theme.fieldBgColor};
  transition: all 0.5s;
  z-index: 2;
  overflow-y: scroll;
  height: 150px;
  width: 100%;
`;

export const DropDownOption = styled.div`
  position: relative;
  display: block;
  padding: 0 22px 0 22px;
  font-size: 1rem;
  font-weight: 400;
  color: ${props => props.theme.hoverColor};
  line-height: 3rem;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.fieldHoverColor};
  }

  &.highlighted {
    background-color: ${props => props.theme.fieldHoverColor};
  }
`;
