import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.tableBgColor};
  max-height: 35rem;
  overflow-y: auto;

  &.extra-padding {
    padding-bottom: 75px;
  }

  &.dropdown-open {
    overflow-y: hidden;
  }
`;

export const SectionWrapper = styled.div`
  background-color: inherit;
  border-bottom: 3px solid ${props => props.theme.bgColor};

  &.input-padding {
    padding: 1.5rem 1.5rem 0;
  }

  & label {
    background-color: ${props => props.theme.sButtonBgColor};
  }
`;

export const SubSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: inherit;
`;

export const SectionSwitchWrapper = styled.div`
  color: ${props => props.theme.textColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.1rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
`;

export const SectionHeadingWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  color: ${props => props.theme.textColor};

  &:focus {
    outline: 0.5px solid ${props => props.theme.highlightColor};
  }

  svg {
    margin-right: 1.25rem;
  }

  &.off {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const SectionTableWrapper = styled.div`
  background-color: inherit;
  padding: 1rem 0;
  border-top: 1.5px solid ${props => props.theme.bgColor};
`;
