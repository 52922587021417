import React, { Fragment, useContext } from 'react';
import {
  HeaderContainer,
  ButtonWrap,
  NameWrap,
  TagWrap,
  SettingsWrap,
} from '../../adminHeader/styles/AdminHeaderStyles';
import { Icon } from '../../../../common/Icon';
import { useLocation, useHistory } from 'react-router-dom';
import TableButtonBar from '../../../../common/buttons/TableButtonBar';
import { AdminContext } from '../../../../context/AdminContext';
import { ThemeContext } from 'styled-components';

export const AdminTableHeader = ({ row, deleted = false }) => {
  const themeContext = useContext(ThemeContext);
  const { name } = row;
  const setContext = useContext(AdminContext).context[1];
  const location = useLocation();
  const history = useHistory();

  const handleDeletedClick = () =>
    !deleted
      ? history.push({
          pathname: '/admin/chests/deleted',
          row,
          prevPathname: location['pathname'],
        })
      : history.push({
          pathname: '/admin/chests/show',
          row,
          prevPathname: location['pathname'],
        });

  const renderHeader = () => {
    return location.pathname.includes('chests') ? renderChestHeader() : renderTeamHeader();
  };

  const renderNewForm = (pathname, text) => {
    setContext(text);
    history.push({
      pathname: pathname,
      prevPathname: history.location.pathname,
    });
  };

  const renderTeamHeader = () => (
    <Fragment>
      <NameWrap>
        {themeContext.scheme === 'dark' ? <Icon name="team_white" width="21px" /> : <Icon name="team" width="21px" />}
        <span>{name}</span>
      </NameWrap>
      <TagWrap>
        Chests: <TableButtonBar array={row['chests']} tags={'chests'} />
        <div role={'to-chests-div'} onClick={() => renderNewForm('/admin/chests/new', 'Chests')}>
          <Icon name="add" width="21px" />
        </div>
      </TagWrap>
      <ButtonWrap>
        <SettingsWrap
          onClick={() =>
            history.push({
              pathname: '/admin/teams/edit',
              row,
              prevPathname: location.pathname,
            })
          }
        >
          Team Settings
        </SettingsWrap>
      </ButtonWrap>
    </Fragment>
  );

  const renderChestHeader = () => (
    <Fragment>
      <NameWrap>
        {themeContext.scheme === 'dark' ? <Icon name="chest_white" width="21px" /> : <Icon name="chest" width="21px" />}
        <span>{name}</span>
      </NameWrap>
      <TagWrap>
        Teams: <TableButtonBar array={row['teams']} tags={'teams'} />
        <div role={'to-teams-div'} onClick={() => renderNewForm('/admin/teams/new', 'Teams')}>
          <Icon name="add" width="21px" />
        </div>
      </TagWrap>
      <ButtonWrap>
        <SettingsWrap
          onClick={() =>
            history.push({
              pathname: '/admin/chests/edit',
              row,
              prevPathname: location['pathname'],
            })
          }
        >
          Chest Settings
        </SettingsWrap>
        <SettingsWrap onClick={handleDeletedClick} className="deleted-secrets-icon">
          <Icon name="bin" width="21px" />
          Deleted Secrets
        </SettingsWrap>
      </ButtonWrap>
    </Fragment>
  );

  return <HeaderContainer>{renderHeader()}</HeaderContainer>;
};
