import React, { useState, useEffect } from 'react';
import { AdminChestsWrap } from './styles/AdminChestsWrap';
import Table from '../../table/Table';
import AdminTableWrap from '../adminTable/styles/AdminTableWrap';
import { getHeaders } from '../adminHeader/AdminHeaderInfo';
import { goToLocation } from '../adminFunctions/redirectByUrl';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { TableBottomLoading } from '../../table/subComponents/styles/TableBottomLoading';
import Loading from '../../../images/loading_02.gif';
import { AdminChestContext } from '../../../context/AdminContext';
import { scrollEnded } from '../../../utils/commonFunctions';
import { getCall } from '../adminFunctions/getApiCall';
import debounce from 'lodash.debounce';

const AdminChests = ({ searchText, context, history }) => {
  const { location } = history;
  const { pathname, refetch } = location;
  const [data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [prevContext, setPrevContext] = useState('');
  const headers = getHeaders(context)();
  const url = goToLocation(context, pathname);

  const getShowPopular = () => context === 'Secrets';

  const redirectTo = row => history.push({ pathname: url, row, context, prevPathname: pathname });

  const chestsColumnWidths = ['25%', '15%', '30%', '12.5%', '12.5%', '5%'];
  const secretsColumnWidths = ['25%', '10%', '20%', '25%', '15%', '5%'];

  const jsonLimit = 20;

  const setDataState = debounce(arr => setData(arr), 300);

  const adminTable = {
    adminChestTable: [data, setDataState],
  };

  if (context !== prevContext) {
    setData([]);
    setPrevContext(context);
  }

  useEffect(() => {
    // P4SS-901: reset the offset whenever context changes to allow infinite scrolling
    setOffset(0);
    let isMounted = true;
    getCall(context)({
      everything: true,
      deleted: false,
      options: ['*'],
      filter: searchText,
      limit: jsonLimit,
      offset: 0,
    }).then(result => {
      if (isMounted) setDataState(result);
    });
    return () => {
      isMounted = false;
    };
  }, [context, searchText, refetch]);

  const handleScrollToBottom = async e => {
    const target = e.target;
    if (scrollEnded(target) && !target.className.includes('modal')) {
      const tableTotal = data[0] && data[0]['total'];
      const newOffset = offset + jsonLimit;
      if (newOffset < tableTotal) {
        setDataLoading(true);
        setOffset(newOffset);
        const newData = await getCall(context)({
          everything: true,
          filter: searchText,
          limit: jsonLimit,
          offset: newOffset,
          options: ['*'],
        });
        newData.length !== 0 ? setData([...data, ...newData]) : setOffset(newOffset - jsonLimit);
        setDataLoading(false);
      }
    }
  };

  if (!data) return <LogoutRedirect />;
  return (
    <AdminChestContext.Provider value={adminTable}>
      <AdminChestsWrap role="admin-chests" className="admin-chests">
        <AdminTableWrap role={'admin-table-wrap'} id="Table" tabIndex="-1" onScroll={handleScrollToBottom}>
          <Table
            role={'table'}
            columnWidths={context === 'Chests' ? chestsColumnWidths : secretsColumnWidths}
            headers={headers}
            data={data}
            onClick={redirectTo}
            showPopular={getShowPopular()}
            isAdmin={true}
            context={context}
          />
          {dataLoading && (
            <TableBottomLoading>
              <img className="small-loading" src={Loading} alt="Loading..." />
            </TableBottomLoading>
          )}
        </AdminTableWrap>
      </AdminChestsWrap>
    </AdminChestContext.Provider>
  );
};
export default AdminChests;
