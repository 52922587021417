import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ChangePassword from '../profile/subComponents/ChangePassword';
import { WelcomePasswordWrap, WelcomePasswordContainer } from './styles/WelcomePasswordStyles';
import { NotificationContext, WelcomeContext } from '../../context';
import { ChangeAccountPassword } from '../../utils/AccountFunctions';
import { UserContext } from '../../context/UserProvider';

const WelcomePassword = () => {
  const { user, setUser } = useContext(UserContext);
  const { welcome, setWelcome } = useContext(WelcomeContext) || {};
  const { setNotificationText } = useContext(NotificationContext);
  const { push } = useHistory();
  const url = setWelcome ? '/welcome' : '/profile/personal_password';

  const changeComplete = err => {
    if (err) return setNotificationText({ type: 'error', text: err });
    if (user['must_change_password']) return setUser({ ...user, must_change_password: false });
    if (setWelcome) setWelcome({ ...welcome, password: true });
    return setTimeout(() => push(url), 3000);
  };

  return (
    <WelcomePasswordWrap>
      <WelcomePasswordContainer>
        <ChangePassword
          title="Set up a personal password"
          email={user.email}
          handleConfirm={data => ChangeAccountPassword(data.oldPass, data.newPass, user.email, changeComplete)}
          handleCancel={() => push(url)}
        />
      </WelcomePasswordContainer>
    </WelcomePasswordWrap>
  );
};

export default WelcomePassword;
