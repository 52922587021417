/*
  This class holds valid but fake versions of common classes
*/
class Dummy {
  user(): RecryptUser {
    return {
      id: 'id',
      name: 'name',
      email: 'email',
      administrator: false,
      dark_mode: true,
      first_time: false,
      has_secret: false,
      inactivity_length: 999,
      locked: true,
      must_change_password: false,
      otp_required_for_login: false,
      created_at: 'created_at',
      updated_at: 'updated_at',
      confirmed_at: 'confirmed_at',
    };
  }
}

export default Dummy;
