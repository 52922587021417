import React from 'react';
import {
  SecretOptionsWrap,
  OptionsTitle,
  OptionsMainText,
  OptionsSubText,
  OptionWrapper,
} from '../../adminChests/styles/EditSecretsOptions';
import ToggleSwitch from '../../../../common/checkboxes/ToggleSwitch';

export const AddUserOptions = ({ notifications, toggleNotifications }) => {
  return (
    <SecretOptionsWrap>
      <OptionsTitle>SETTINGS</OptionsTitle>
      <OptionWrapper>
        <OptionsMainText>
          <i className="fas fa-bell"></i>NOTIFICATIONS
        </OptionsMainText>
        <ToggleSwitch
          id="user-notifications-toggle"
          onClick={() => toggleNotifications(!notifications)}
          checked={notifications}
        />
      </OptionWrapper>
      <OptionsSubText>Send notifications to the user</OptionsSubText>
    </SecretOptionsWrap>
  );
};
