import styled from 'styled-components';

const AdminTeamsWrap = styled.div`
  display: flex;
  flex: 12;
  background-color: ${props => props.theme.tableBgColor};

  &.table {
    flex-direction: column;
  }
`;

export default AdminTeamsWrap;
