import React, { useContext, useState } from 'react';
import { IconWrap, ButtonWrap, Confirm, Cancel } from './styles/ConfirmationModalStyles';
import { Icon } from '../../Icon';
import { placeholderFunc } from '../../../utils/commonFunctions';
import ModalWrap from './ModalWrap';
import Escape from './subComponents/Escape';
import { Error, PassInput } from './styles/SecureConfirmationModalStyles';
import { CheckPassword } from '../../../utils/AccountFunctions';
import { Title } from './styles/GenericStyles';
import { DropdownWrap } from './styles/TransferModalStyles';
import SearchableDropdown from '../../SearchableDropdown';
import { UserContext } from '../../../context/UserProvider';

const TransferModal = ({
  icon = 'alert',
  title,
  placeholder,
  adminList,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmAction = placeholderFunc,
  cancelAction = placeholderFunc,
  escape,
}) => {
  const [selection, setSelection] = useState();
  const [pass, setPass] = useState('');
  const [error, setError] = useState('errorPlaceholder');
  const { user } = useContext(UserContext);
  const { email } = user;

  const handleConfirm = async () => {
    setError('errorPlaceholder');
    if (!selection) return setError('Please pick an administrator.');
    const passMatched = await CheckPassword(email, pass);
    if (passMatched) confirmAction(selection);
    if (!passMatched) setError('Password entered is incorrect.');
  };

  return (
    <ModalWrap cancelAction={cancelAction}>
      {escape && <Escape cancelAction={cancelAction} />}
      <IconWrap>
        <Icon name={icon} width={'36px'} />
      </IconWrap>
      <Title>{title}</Title>
      <DropdownWrap>
        <SearchableDropdown data={adminList} setValue={setSelection} />
      </DropdownWrap>
      <PassInput type={'password'} placeholder={placeholder} value={pass} onChange={e => setPass(e.target.value)} />
      <Error visible={error !== 'errorPlaceholder'}>{error}</Error>
      <ButtonWrap>
        <Confirm onClick={handleConfirm} tabIndex="0" id="confirm-button">
          {confirmText}
        </Confirm>
        <Cancel onClick={cancelAction} tabIndex="0" id="cancel-button">
          {cancelText}
        </Cancel>
      </ButtonWrap>
    </ModalWrap>
  );
};

export default TransferModal;
