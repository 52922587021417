import React, { forwardRef } from 'react';
import { SearchContainer, InputWrapper } from './styles/SearchBarStyles';
import { blur } from '../../utils/commonFunctions';

const handleChange = (e, setSearchText) => setSearchText(e.target.value);

const SearchBar = ({ placeholder, setSearchText }, ref) => {
  const handleKey = key => {
    if (key === 'Escape') {
      blur('searchbar');
    }
    // This focuses the first row of table if down arrow pressed
    if (key === 'ArrowDown') {
      return document.getElementsByClassName('focused')['0'] && document.getElementsByClassName('focused')['0'].focus();
    }
  };

  return (
    <SearchContainer>
      <InputWrapper>
        <input
          type="text"
          placeholder={placeholder}
          onChange={e => handleChange(e, setSearchText)}
          id="searchbar"
          autoFocus
          className="mousetrap"
          onKeyDown={e => handleKey(e.key)}
          ref={ref}
        />
        <i className="fas fa-search"></i>
      </InputWrapper>
    </SearchContainer>
  );
};

export default forwardRef(SearchBar);
