import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import { TextInput } from '../../common/forms/TextInput';
import { FormWrapper, SectionWrapper, SectionHeadingWrapper } from './styles/AdminFormStyle.jsx';
import { Icon } from '../../common/Icon';
import { blur, focus } from '../../utils/commonFunctions';
import { ModalContext } from '../../context';
import { reverseAll } from '../../utils/arrayFunctions';
import LinkFormSection from './subComponents/LinkFormSection';

export const TeamForm = ({ teamName, setTeamName, chests, setChests, users, setUsers }) => {
  const { setModal } = useContext(ModalContext);
  const history = useHistory();
  const addedObj = arr => arr.filter(obj => obj.added === true);

  // useEffect for keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', escShortcut);
      Mousetrap.bind('i', () => focus('team-name-input'), 'keyup');
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc');
      Mousetrap.unbind('i', 'keyup');
    };
  });

  // Function for escape key
  const escShortcut = () =>
    document.activeElement === document.getElementById('team-name-input')
      ? blur('team-name-input')
      : history.push({
          pathname: '/admin/teams',
          prevPathname: history.location.pathname,
        });

  const handleChestsModalConfirm = array => {
    setChests(reverseAll(chests, array, 'added'));
    setModal();
  };

  const handleUsersModalConfirm = array => {
    setUsers(reverseAll(users, array, 'added'));
    setModal();
  };

  const renderLinkedChests = () => <LinkFormSection selectedArr={chests} setSelectedArr={setChests} name={'Chest'} />;

  const renderTeamUsers = () => <LinkFormSection selectedArr={users} setSelectedArr={setUsers} name={'User'} />;

  const chestLinkModal = () =>
    setModal({
      key: 'Link',
      params: {
        headerText: 'Link: Chests',
        data: chests,
        confirmAction: handleChestsModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  const userLinkModal = () =>
    setModal({
      key: 'Link',
      params: {
        headerText: 'Link: Users',
        data: users,
        confirmAction: handleUsersModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  return (
    <FormWrapper id="form-wrapper">
      <SectionWrapper className="input-padding">
        <TextInput
          placeholder="Team Name"
          label="Team Name"
          value={teamName}
          handleChange={value => setTeamName(value)}
          id="team-name-input"
          autoFocus
          mousetrap
          maxLength="25"
        />
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeadingWrapper
          onClick={chestLinkModal}
          tabIndex="0"
          onKeyPress={e => (e.key === 'Enter' ? chestLinkModal() : null)}
        >
          <Icon name="add" width="21px" />
          Link this Team to a Chest or multiple Chests
        </SectionHeadingWrapper>
        {addedObj(chests).length > 0 && renderLinkedChests()}
      </SectionWrapper>
      <SectionWrapper>
        <SectionHeadingWrapper
          role={'add-team-members-button'}
          onClick={userLinkModal}
          tabIndex="0"
          onKeyPress={e => (e.key === 'Enter' ? userLinkModal() : null)}
        >
          <Icon name="add" width="21px" />
          Add Team Members
        </SectionHeadingWrapper>
        {addedObj(users).length > 0 && renderTeamUsers()}
      </SectionWrapper>
    </FormWrapper>
  );
};
