import { useEffect } from 'react';
import Mousetrap from 'mousetrap';
const useCycle = className => {
  // Keyboard shortcuts
  const handleEnter = e => e.target.click();
  const handleArrow = mod => {
    const elements = Array.from(document.getElementsByClassName(className));
    if (elements.length <= 0) return;
    const elIndex = elements.findIndex(el => el === document.activeElement);
    if (elIndex === -1) return elements[0].focus();
    if (mod === 1 && elements.length === elIndex + 1) return elements[0].focus();
    if (mod === -1 && elIndex === 0) return elements[elements.length - 1].focus();
    elements[elIndex] && elements[elIndex + mod].focus();
  };
  Mousetrap.bind('enter', handleEnter);
  Mousetrap.bind('up', () => handleArrow(-1));
  Mousetrap.bind('down', () => handleArrow(1));
  useEffect(() => {
    Mousetrap.bind('enter', handleEnter);
    Mousetrap.bind('up', () => handleArrow(-1));
    Mousetrap.bind('down', () => handleArrow(1));
    return () => {
      Mousetrap.unbind('enter');
      Mousetrap.unbind('up');
      Mousetrap.unbind('down');
    };
  }, [className]);
};

export default useCycle;
