import React, { useState, useEffect, useContext } from 'react';
import { Switch, useLocation, useHistory } from 'react-router-dom';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import { AppContent } from './styles/AppStyle';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { darkTheme, lightTheme } from './styles/Colors';
import { NotificationContext, StripeContext, ModalContext } from './context/index';
import PageSwitch from './pages/PageSwitch';
import ProfileApi from './api/profiles/ProfileApi';
import { Register } from './pages/Register';
import Login from './pages/Login';
import { Notification } from './common/notification/Notification';
import { loadStripe } from '@stripe/stripe-js';
import PublicRoute from './common/nav/PublicRoute';
import { LoadingContent } from './pages/styles/LoadingPage';
import { getModal } from './common/modals/GetModal';
import LoadingIcon from './images/loading_01-1.gif';
import UserProvider from './context/UserProvider';
import LocalStore from './api/LocalStore';
import Request from './api/Request';
import {
  InactivityTimeoutContextProvider,
  InactivityTimeoutContext,
} from './context/InactivityTimeoutContextManagment';
import { AppLoadingContext } from './context/index';

const stripeKey = `${process.env.REACT_APP_STRIPE_API_KEY}`;
const stripePromise = loadStripe(`${stripeKey}`);

const GlobalStyle = createGlobalStyle`
  * {
    scrollbar-width: thin;
    scrollbar-color:  ${props => props.theme.placeholderColor}  ${props => props.theme.bgColor};
  }

  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background: ${props => props.theme.bgColor};
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.placeholderColor};
    border-radius: 20px;
    border: 3px solid ${props => props.theme.bgColor};
  }

  @media screen and (max-width: 767px) {
    * {
      scrollbar-width: none;
    }
    *::-webkit-scrollbar { display: none; }
  }

  .small-loading { max-width: 8rem; }
`;

const App = () => {
  const { inactivityLength, setInactivityLength } = useContext(InactivityTimeoutContext);
  const [user, setUser] = useState({});
  const [displayDashboard, setDisplayDashboard] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [notificationText, setNotificationText] = useState();
  const [modal, setModal] = useState();
  const store = new LocalStore();

  const footerVisible = () => typeof modal !== 'string' || modal === undefined;

  const stripeCalls = async userRes => {
    const { administrator } = userRes;
    if (!administrator) return;
    const customerReq = new Request('GET', '/api/v1/billing_api/customers');
    const customer = await customerReq.perform();
    const trialled = customer['json']['error'] ? false : customer['json']['metadata']['trialled'] === 'true';
    const subReq = new Request('GET', '/api/v1/billing_api/subscriptions');
    const subscription = await subReq.perform();
    const subscribed = subscription['json']['status'] === 'active';
    setUser({ ...userRes, trialled, subscription: subscription['json'], subscribed });
  };

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    ProfileApi.show().then(res => {
      if (!res) {
        if (isMounted) {
          setUser({});
          setLoading(false);
        }
      } else if (res['error'] === 'Signature has expired') {
        store.clear();
        history.push('/login');
        setNotificationText('Your session has expired.');
        setLoading(false);
      } else {
        if (isMounted) {
          setInactivityLength(res.inactivity_length);
          setLoading(false);
          setUser(res);
          stripeCalls(res);
        }
      }
    });
    return () => (isMounted = false);
  }, [inactivityLength, setInactivityLength]);

  const returnTheme = store.get('dark_mode') ? darkTheme : lightTheme;

  return (
    <AppLoadingContext.Provider value={{ loading, setLoading }}>
      <UserProvider user={user} setUser={setUser}>
        <StripeContext.Provider value={stripePromise}>
          <ThemeProvider theme={returnTheme}>
            <ModalContext.Provider value={{ modal, setModal }}>
              <InactivityTimeoutContextProvider>
                <Switch>
                  <NotificationContext.Provider value={{ notificationText, setNotificationText }}>
                    <PublicRoute path="/login" component={Login} history={history} />
                    <PublicRoute path="/register" component={Register} history={history} />
                    {pathname !== '/login' && pathname !== '/register' && (
                      <AppContent>
                        {modal && typeof modal === 'object' && getModal(modal['key'])(modal['params'])}
                        <Header setDisplayDashboard={setDisplayDashboard} />
                        <Notification notificationText={notificationText} />
                        {loading ? (
                          <LoadingContent>
                            <img src={LoadingIcon} alt="Loading" />
                          </LoadingContent>
                        ) : (
                          <PageSwitch setDisplayDashboard={setDisplayDashboard} displayDashboard={displayDashboard} />
                        )}
                        <Footer visible={footerVisible()} />
                        <GlobalStyle />
                      </AppContent>
                    )}
                  </NotificationContext.Provider>
                </Switch>
              </InactivityTimeoutContextProvider>
            </ModalContext.Provider>
          </ThemeProvider>
        </StripeContext.Provider>
      </UserProvider>
    </AppLoadingContext.Provider>
  );
};

export default App;
