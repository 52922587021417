export const sortTable = key => sortFuncs[key] || _returnData;

const _numberSort = (header, selectedHeader, data) => {
  const sortedData = data.sort((a, b) => b[header] - a[header]);
  if (selectedHeader['reverse']) return sortedData.reverse();
  return sortedData;
};

const _dateSort = (header, selectedHeader, data) => {
  const sortedData = data.sort((a, b) => {
    const dateA = new Date(a[header]);
    const dateB = new Date(b[header]);
    return dateB - dateA;
  });
  if (selectedHeader['reverse']) return sortedData.reverse();
  return sortedData;
};

const _stringSort = (header, selectedHeader, data) => {
  const sortedData = data.sort((a, b) => {
    const strA = `${a[header]}`.toLowerCase();
    const strB = `${b[header]}`.toLowerCase();
    if (strA < strB) return -1;
    if (strA > strB) return 1;
    return 0;
  });
  if (selectedHeader['reverse']) return sortedData.reverse();
  return sortedData;
};

const _returnData = (header, selectedHeader, data) => data;

const sortFuncs = {
  number: _numberSort,
  date: _dateSort,
  string: _stringSort,
};
