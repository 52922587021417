import React from 'react';
import { ReactComponent as FourSquares } from '../../images/recrypt_4_squares.svg';
import { ReactComponent as GmailIcon } from '../../images/recrypt_icons_gmail.svg';
import { ReactComponent as OutlookIcon } from '../../images/recrypt_icons_outlook.svg';
import { ReactComponent as YahooIcon } from '../../images/recrypt_icons_yahoo.svg';
import { Heading, Subheading, InfoWrapper, EmailLink, TextLink } from './styles/ComponentStyles';

export const EmailConfirmation = ({ email }) => {
  return (
    <InfoWrapper>
      <FourSquares width="90px" />
      <Heading>Check your mailbox</Heading>
      <Subheading className="text-center">
        An email was sent to <b>{email}</b>. Please click on the link to get started.
      </Subheading>
      <EmailLink href={`${process.env.REACT_APP_GMAIL_URL}`} target="_blank">
        <GmailIcon width="24px" />
        Open Gmail
      </EmailLink>
      <EmailLink href={`${process.env.REACT_APP_OUTLOOK_URL}`} target="_blank">
        <OutlookIcon width="24px" />
        Open Outlook
      </EmailLink>
      <EmailLink href={`${process.env.REACT_APP_YAHOOMAIL_URL}`} target="_blank">
        <YahooIcon width="24px" />
        Open Yahoo
      </EmailLink>
      <TextLink>Resend email</TextLink>
    </InfoWrapper>
  );
};
