import styled from 'styled-components';

export const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 6vh;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  margin: 20px 20px 0 20px;

  @media screen and (max-width: 767px) {
    margin: 0;
    justify-content: center;
    padding: 1rem 3%;
    max-height: 50px;

    p:empty {
      display: none;
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderImageWrap = styled.div`
  display: flex;
  flex: 5;

  svg {
    height: 100%;
    width: 200px;

    @media screen and (max-width: 767px) {
      width: 150px;
    }

    @media (-webkit-device-pixel-ratio: 1.5) {
      width: 135px;
    }
  }
`;

export const HeaderLinks = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    color: ${props => props.theme.textColor};
    outline: 0;

    &:hover {
      color: ${props => props.theme.hoverColor};
    }

    > i {
      padding-left: 1rem;
      font-size: 25px;
    }
  }
  @media screen and (max-width: 767px) {
    flex: 4;
    justify-content: flex-end;
    p {
      margin-top: -4px;
    }
    div > p {
      display: none;
    }
  }
`;

export const AdminIcon = styled.div`
  line-height: 0px;

  .st0 {
    fill: ${({ theme }) => theme.textColor};
  }

  &:hover {
    .st0 {
      fill: ${({ theme }) => theme.highlightColor};
    }
  }
`;
