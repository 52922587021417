import React, { useState } from 'react';
import { CopyText, IconButton } from './styles/FullTextStyles';
import copy from 'copy-to-clipboard';
import { FullWrapperFieldset, Legend, InnerWrapper } from '../../../common/forms/styles/TextInputStyles';
import { UrlWrapper } from './styles/UrlWrapper';

export const UrlView = ({ label, text, className }) => {
  const [copied, setCopy] = useState(false);

  const writeToClipboard = text => {
    copy(text);
    setCopy(true);
    setTimeout(() => setCopy(false), 1000);
  };

  const renderCopy = () => {
    return copied ? (
      <CopyText>Copied</CopyText>
    ) : (
      <IconButton role={'icon-button'} className="fas fa-copy" onClick={() => writeToClipboard(text)}></IconButton>
    );
  };

  const legendClassName = () => {
    let classname = '';
    if (text === '') classname = classname.concat('empty ');
    if (className === 'error') classname = classname.concat('error');
    return classname;
  };

  return (
    <FullWrapperFieldset className={className}>
      <Legend className={legendClassName()}>{label}</Legend>
      <InnerWrapper>
        <UrlWrapper value={text} onClick={() => window.open(text, 'tab', 'noopener=yes')} readOnly />
        {renderCopy()}
      </InnerWrapper>
    </FullWrapperFieldset>
  );
};
