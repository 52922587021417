import React, { useContext, useState, useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { OptionsModalContext } from '../../../../../context/OptionsModalContext';
import { useHistory } from 'react-router-dom';
import BaseApi from '../../../../../api/BaseApi';
import { AdminTeamContext, AdminContext } from '../../../../../context/AdminContext';
import { TeamTableData } from '../../../../../api/teams/TeamApiFunctions';
import { IconArea } from './styles/BurgerMenuDropdownStyles';
import { BurgerMenuDropdown } from './BurgerMenuDropdown';
import { ModalContext } from '../../../../../context';

export const AdminTeamActions = ({ row, highlightedRow }) => {
  const [optionsVisible, setOptions] = useContext(OptionsModalContext);
  const [highlightedOption, setHighlightedOption] = useState();
  const { setModal } = useContext(ModalContext);
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;
  const setTeamTags = useContext(AdminContext).teamTags[1];
  const setData = useContext(AdminTeamContext).adminTeamTable[1];

  const menuOptions = [
    {
      text: 'Add User',
      handleClick: () => addUser(),
      styleId: 'team-add-user',
    },
    { text: 'Edit Team', handleClick: () => editTeam(), styleId: 'team-edit' },
    {
      text: 'Remove Team',
      handleClick: () => deletePopUp(),
      styleId: 'team-remove',
    },
  ];

  const refreshTable = async () => {
    const teamData = await TeamTableData({ everything: true });
    setTeamTags(teamData);
    setData(teamData);
  };

  const addUser = () => {
    setOptions({});
    history.push({ pathname: '/admin/teams/user/new', prevPathname: pathname });
  };

  const editTeam = () => {
    setOptions({});
    history.push({
      pathname: '/admin/teams/edit',
      row,
      prevPathname: pathname,
    });
  };

  const handleDeleteModalConfirm = () => {
    BaseApi.destroy('teams', row.id).then(() => {
      refreshTable();
      setModal();
    });
  };

  const deletePopUp = () =>
    setModal({
      key: 'Confirm',
      params: {
        icon: 'alert',
        question: `Are you sure you want to delete ${row['name']}?`,
        subtext: 'This team will not be recoverable.',
        confirmText: `Yes, delete ${row['name']}`,
        cancelText: `No, keep ${row['name']}`,
        confirmAction: handleDeleteModalConfirm,
        cancelAction: () => setModal(),
      },
    });

  const handleOptions = (row, e) => {
    setHighlightedOption('');
    optionsVisible.row !== row.id ? setOptions({ row: row.id, above: calculateOptionsPosition(e) }) : setOptions({});
  };

  const calculateOptionsPosition = e => {
    return e.clientY > window.innerHeight * 0.6 || window.innerHeight - e.clientY < 250 ? true : false;
  };

  const handleMouseLeave = () => {
    setOptions({});
    setHighlightedOption('');
  };

  // useEffect for keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('right', e => handleOptions(highlightedRow, e));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('right');
    };
  });

  return (
    <div style={{ position: 'relative' }} onMouseLeave={() => handleMouseLeave()}>
      <IconArea role={'Icon'} onClick={e => handleOptions(row, e)} className="icon-area">
        <i className="fas fa-bars"></i>
      </IconArea>
      {optionsVisible.row === row.id && (
        <BurgerMenuDropdown
          menuOptions={menuOptions}
          highlightedOption={highlightedOption}
          setHighlightedOption={setHighlightedOption}
        />
      )}
    </div>
  );
};
