import { Route, Redirect } from 'react-router-dom';
import LocalStore from '../../api/LocalStore';

const PublicRoute = ({ ...props }) => {
  const pathname = props.history.location.pathname;
  const prevPathname = props.history.location.prevPathname;
  const token = new LocalStore().get('token');
  if (!token) {
    return <Route {...props} />;
  } else if (pathname === '/login' || pathname === '/register') {
    return <Redirect to="/" />;
    // when page is refreshed, prevPathname is undefined, and pathname is what we were on when refreshing
  } else if (prevPathname === undefined) {
    return <Redirect to={{ ...props.history.location, prevPathname: pathname }} />;
  } else {
    return <Redirect to={{ ...props.history.location }} />;
  }
};

export default PublicRoute;
