import styled from 'styled-components';

const AdminTableWrap = styled.div`
  flex: 11;
  overflow-y: auto;
  background-color: ${props => props.theme.tableBgColor};
  outline: none;

  &.modal-open {
    overflow-y: hidden;
  }
`;

export default AdminTableWrap;
