import React, { useEffect } from 'react';
import Mousetrap from 'mousetrap';
import { placeholderFunc } from '../../../utils/commonFunctions';
import ModalWrap from './ModalWrap';
import Escape from './subComponents/Escape';
import {
  TitleWrap,
  Title,
  Subtitle,
  BoxWrap,
  Box,
  SubmissionWrap,
  SubmissionText,
  Button,
  BoxCol,
  IconWrap,
  BoxTitle,
  BoxText,
  BoxPlanText,
  BoxPlanTitle,
  BoxPriceWrap,
  BoxPriceCurrency,
  BoxPriceNum,
  BoxPriceUnit,
  BoxPriceText,
} from './styles/BillingAlertModalStyles';
import { Icon } from '../../Icon';
import { boxChestText, boxTeamsText } from './strings/BillingAlertModalStrings';

const BillingAlertModal = ({
  title,
  subtext,
  confirmAction = placeholderFunc,
  cancelAction = placeholderFunc,
  escape,
}) => {
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', cancelAction, 'keyup');
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc', 'keyup');
    };
  });

  return (
    <ModalWrap modalStyle={'largeSquared'} cancelAction={cancelAction} padType={'None'}>
      {escape && <Escape escapeModal cancelAction={cancelAction} />}
      <TitleWrap>
        <Title>{title}</Title>
        <Subtitle>{subtext}</Subtitle>
      </TitleWrap>
      <BoxWrap>
        <BoxCol>
          <Box>
            <IconWrap>
              <Icon name="team" width="24px" />
            </IconWrap>
            <BoxTitle>Invite Your Team(s)</BoxTitle>
            <BoxText>{boxTeamsText}</BoxText>
          </Box>
          <Box>
            <BoxPriceWrap>
              <BoxPriceCurrency>£</BoxPriceCurrency>
              <BoxPriceNum>2</BoxPriceNum>
              <BoxPriceUnit>/user</BoxPriceUnit>
            </BoxPriceWrap>
            <BoxPriceText>£2 per user / per month</BoxPriceText>
            <BoxPriceText>Billed monthly / cancel any time</BoxPriceText>
          </Box>
        </BoxCol>
        <BoxCol>
          <Box>
            <IconWrap>
              <Icon name="chest" width="24px" />
            </IconWrap>
            <BoxTitle>Personalize Your Experience</BoxTitle>
            <BoxText>{boxChestText}</BoxText>
          </Box>
          <Box>
            <BoxPlanTitle>Team plan includes</BoxPlanTitle>
            <BoxPlanText>Up to 50 users</BoxPlanText>
            <BoxPlanText>Unlimited secrets</BoxPlanText>
            <BoxPlanText>Unlimited chests/team sharing</BoxPlanText>
          </Box>
        </BoxCol>
      </BoxWrap>
      <SubmissionWrap>
        <SubmissionText>Take 30 free days on us</SubmissionText>
        <Button onClick={confirmAction}>Activate Plan</Button>
      </SubmissionWrap>
    </ModalWrap>
  );
};

export default BillingAlertModal;
