import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { AdminChestsWrap } from '../adminChests/styles/AdminChestsWrap';
import SecretForm from '../../forms/SecretForm';
import ToggleSwitch from '../../../common/checkboxes/ToggleSwitch';
import { DecryptSecret } from '../../../utils/secretFunctions';
import {
  SecretOptionsWrap,
  OptionsTitle,
  OptionWrapper,
  OptionsMainText,
  OptionsSubText,
} from '../adminChests/styles/EditSecretsOptions';
import { AdminSecretsEditWrap } from './styles/AdminSecretsEditWrap';
import { ViewSecretData } from '../../../api/secrets/SecretApiFunctions';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { AdminContext } from '../../../context/AdminContext';
import { ChestTableData } from '../../../api/chests/ChestApiFunctions';

export const AdminSecretsEdit = () => {
  const [secretData, setSecretData] = useState();
  const [redirect, setRedirect] = useState(false);
  const location = useLocation();
  const row = useMemo(() => location['row'] || (location['state'] && location['state']['row']) || [], [location]);
  const [hideSecret, toggleHide] = useState(false);
  const setChestTags = useContext(AdminContext).chestTags[1];

  useEffect(() => {
    let isMounted = true;
    if (row.length !== 0) {
      ViewSecretData(row.id).then(async secret => {
        if (isMounted && secret) {
          const decryptedSecret = await DecryptSecret(secret);
          setSecretData({ ...decryptedSecret, ...row });
        } else {
          if (isMounted) setRedirect(true);
        }
      });
    }
    return () => (isMounted = false);
  }, [row]);

  const refreshSide = () => {
    ChestTableData({ everything: true }).then(result => {
      setChestTags(result);
    });
  };

  if (row.length === 0) return <Redirect to={'/admin/chests/secret'} />;
  if (!secretData && redirect) return <LogoutRedirect />;
  return (
    <AdminChestsWrap className="form-footer team-form">
      <AdminSecretsEditWrap>
        {secretData && (
          <SecretForm secret={!secretData ? 0 : secretData} admin={true} edit={true} refresh={refreshSide} />
        )}
      </AdminSecretsEditWrap>
      <SecretOptionsWrap>
        <OptionsTitle>MORE OPTIONS</OptionsTitle>
        <OptionWrapper>
          <OptionsMainText>
            <i className="far fa-eye-slash"></i>HIDE SECRET
          </OptionsMainText>
          <ToggleSwitch id="hide-secret-toggle" onClick={() => toggleHide(!hideSecret)} checked={hideSecret} />
        </OptionWrapper>
        <OptionsSubText>Don't show this secret to users</OptionsSubText>
      </SecretOptionsWrap>
    </AdminChestsWrap>
  );
};
