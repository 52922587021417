import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { WelcomeContext } from '../../context/index';
import { WelcomeWrap } from '../welcome/styles/WelcomeMenuStyles';
import {
  ImageContainer,
  ImageHeader,
  ImageText,
  SlideBox,
  ImageNext,
  DotWrapper,
  Dot,
  LinkWrap,
} from './styles/TutorialStyles';
import { slides } from './Slides';
import Mousetrap from 'mousetrap';
import { UserContext } from '../../context/UserProvider';

export const Tutorial = () => {
  const [slide, setSlide] = useState(0);
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { administrator } = user;
  const { welcome, setWelcome } = useContext(WelcomeContext);

  const renderSlide = ({ heading, text, image }) => (
    <Fragment>
      <ImageContainer src={image} alt={heading} />
      <ImageHeader>{heading}</ImageHeader>
      <ImageText>{text}</ImageText>
    </Fragment>
  );

  // Keyboard shortcuts
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('enter', nextSlide);
      Mousetrap.bind('backspace', () => slide !== 0 && setSlide(slide - 1));
      Mousetrap.bind('left', () => slide !== 0 && setSlide(slide - 1));
      Mousetrap.bind('right', nextSlide);
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('enter');
      Mousetrap.unbind('backspace');
      Mousetrap.unbind('left');
      Mousetrap.unbind('right');
    };
  });

  const nextSlide = () => {
    if (slide < slides.length - 1) return setSlide(slide + 1);
    if (administrator) {
      setWelcome({
        ...welcome,
        tutorial: true,
      });
    } else {
      setWelcome({
        ...welcome,
        password: true,
        tutorial: true,
      });
    }
    if (!welcome.twofactor || !welcome.secret) history.push('/welcome');
  };

  const transitionWord = () => {
    if (slide < slides.length - 1) return 'Next';
    return 'Finish';
  };

  return (
    <WelcomeWrap>
      <SlideBox>
        {renderSlide(slides[slide])}
        <LinkWrap>
          {slide !== 0 && <ImageNext onClick={() => setSlide(slide - 1)}>Previous</ImageNext>}
          <ImageNext next onClick={() => nextSlide()}>
            {transitionWord()}
          </ImageNext>
        </LinkWrap>
        <DotWrapper>
          {slides.map((v, index) => (
            <Dot key={v.heading} onClick={() => setSlide(index)} className={index === slide ? 'selected' : null}></Dot>
          ))}
        </DotWrapper>
      </SlideBox>
    </WelcomeWrap>
  );
};
