import React, { Fragment, useState, useRef } from 'react';
import { TBody } from './styles/TableBodyStyles';
import { generateRows } from '../subFunctions/bodyFunctions';
import useCycle from '../../../utils/hooks/shortcuts/useCycle';

const TableBody = ({ handleRow, data, jsonKeys, params, columnWidths, isAdmin, role = 'table', headers }) => {
  const tableRef = useRef();
  const [highlightedRow, setHighlight] = useState();
  useCycle('table-row');

  const enterPress = event => {
    if (!document.activeElement.className.includes('table-button') && event.key === 'Enter') {
      return handleRow && !isAdmin ? handleRow(data[highlightedRow]) : event.target.click();
    }
  };

  const onHighlight = rowIndex => {
    const rows = tableRef.current.children;
    for (let item of rows) {
      if (item && item.classList.contains('focused')) item.classList.remove('focused');
    }
    setHighlight(rowIndex);
  };

  const highlight = data[highlightedRow];

  return (
    <Fragment>
      <TBody
        id={'table'}
        role={role}
        ref={tableRef}
        onMouseMove={() => onHighlight()}
        onKeyDown={event => enterPress(event)}
      >
        {generateRows(jsonKeys, data, handleRow, onHighlight, params, columnWidths, highlight, role, headers)}
      </TBody>
    </Fragment>
  );
};

export default TableBody;
