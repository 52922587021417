import React, { useEffect, Fragment, useState } from 'react';
import zxcvbn from 'zxcvbn';
import { Icon, PasswordInput } from '../styles/FormBodyStyle';
import ProtocolAddressInput from './ProtocolAddressInput';
import PasswordMeter from '../../../common/forms/PasswordMeter';
import { TextWrapper } from '../../../common/forms/styles/TextWrapper';
import { SplitWrapper } from '../../../common/forms/styles/SplitTextStyles';
import { getSecretFormat } from '../subFunctions/FormFunctions';
import { TextInput } from '../../../common/forms/TextInput';
import { MeterWrap } from './styles/LoginCredentialsStyles';
import { errorCheck, removeError } from '../../../utils/formFunctions';
import { generatePassword } from '../../../utils/commonFunctions';
import NoteArea from '../../../common/forms/NoteArea';

const LoginCredentials = ({
  secret,
  data,
  setData,
  setStrength,
  passwordStrength,
  notificationText,
  setNotificationText,
  edit,
}) => {
  const [visibility, setVisibility] = useState(false);
  const { username, password, url, note } = getSecretFormat(secret);

  useEffect(() => {
    setData({ username, password, url, note });
  }, [setData, username, password, url, note]);

  const assessPasswordStrength = customPassword => {
    handleChange(customPassword, 'password');
    const strength = zxcvbn(customPassword).score + 1;
    setStrength(strength);
  };

  const handleChange = (value, key) => {
    if (notificationText) setNotificationText(removeError(key, notificationText));
    setData(prevData => {
      return {
        ...prevData,
        [key]: value,
      };
    });
  };

  const handlePassGen = () => assessPasswordStrength(generatePassword(true, true, true, true, 12), 'password');
  const handleVisibility = () => setVisibility(!visibility);

  return (
    <Fragment>
      <span className="custom-title">Login Credentials</span>
      <SplitWrapper>
        <TextInput
          placeholder="Username"
          label="Username"
          value={data.username || ''}
          handleChange={value => handleChange(value, 'username')}
          className={errorCheck('username', notificationText)}
        />
        <TextWrapper error={errorCheck('password', notificationText) === 'error'}>
          <MeterWrap>
            <PasswordMeter strength={passwordStrength} />
          </MeterWrap>
          <PasswordInput
            value={data.password || ''}
            type={!visibility && 'password'}
            placeholder="Password"
            onChange={e => assessPasswordStrength(e.target.value)}
          />
          <Icon role={'password-generator-icon'} className="fas fa-dice" onClick={handlePassGen}></Icon>
          <Icon
            role={'toggle-visibility-icon'}
            className={`fas fa-eye${visibility ? '-slash' : ''}`}
            onClick={handleVisibility}
          ></Icon>
        </TextWrapper>
      </SplitWrapper>
      <ProtocolAddressInput value={data.url || ''} handleChange={handleChange} edit={edit} />
      <NoteArea
        placeholder="Note"
        label="Note"
        value={data.note || ''}
        onChange={e => handleChange(e.target.value, 'note')}
      />
    </Fragment>
  );
};

export default LoginCredentials;
