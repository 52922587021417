import styled from 'styled-components';

export const SecretHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.highlightColor};
  width: 100%;
  color: ${props => props.theme.hoverRowColor};
  align-items: center;
  padding: 0.15rem 1.25rem;
  box-sizing: border-box;

  svg {
    margin-right: 10px;

    @media (-webkit-device-pixel-ratio: 1.5) {
      max-width: 16px;
    }
  }
`;
