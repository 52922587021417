import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import Mousetrap from 'mousetrap';
import AdminTableWrap from '../adminTable/styles/AdminTableWrap';
import { AdminTableHeader } from '../adminChests/subComponents/AdminTableHeader';
import Table from '../../table/Table';
import { getHeaders } from '../adminHeader/AdminHeaderInfo';
import { getSearchable } from '../../table/subFunctions/searchableFunctions';
import AdminTeamsWrap from './styles/AdminTeamsWrap';
import { goToLocation } from '../adminFunctions/redirectByUrl';
import { UserTableData } from '../../../api/users/UserApiFunctions';
import LogoutRedirect from '../../../common/nav/LogoutRedirect';
import { AdminTeamContext, AdminContext } from '../../../context/AdminContext';
import { ModalContext } from '../../../context';

const AdminTeamsShow = ({ searchText, context, history }) => {
  const [data, setData] = useState([]);
  const setSearchPlaceholder = useContext(AdminContext).searchPlaceholder[1];
  const setContext = useContext(AdminContext).context[1];
  const { modal } = useContext(ModalContext);
  const { location } = history;
  const row = useMemo(() => location['row'] || (location['state'] && location['state']['row']) || [], [location]);
  const headers = getHeaders('Users')();
  const columnWidths = ['20%', '30%', '30%', '15%', '5%'];
  const searchable = getSearchable('TeamsShow');
  const pathname = location['pathname'] || '/admin/teams';
  const url = goToLocation(context, pathname);
  const redirectTo = row => history.push({ pathname: url, row, context, prevPathname: pathname });

  const adminTable = {
    adminTeamTable: [data, setData],
  };

  useEffect(() => {
    let isMounted = true;
    UserTableData({ everything: true }).then(result => {
      if (isMounted) {
        setData(result.filter(user => user['teams'].filter(team => team['id'] === row['id']).length > 0));
        setSearchPlaceholder('Search for a user');
        setContext('Teams');
      }
    });
    return () => (isMounted = false);
  }, [row, setSearchPlaceholder, setContext]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      Mousetrap.bind('esc', () => !modal && history.push({ pathname: '/admin/teams', prevPathname: pathname }));
    }
    return () => {
      isMounted = false;
      Mousetrap.unbind('esc');
    };
  });

  if (row.length === 0) return <Redirect to={'/admin/teams'} />;
  if (!data) return <LogoutRedirect />;
  return (
    <AdminTeamContext.Provider value={adminTable}>
      <AdminTeamsWrap className={'table'}>
        <AdminTableHeader row={row} />
        <AdminTableWrap id="Table">
          <Table
            columnWidths={columnWidths}
            headers={headers}
            data={data}
            searchable={searchable}
            searchText={searchText}
            onClick={redirectTo}
          />
        </AdminTableWrap>
      </AdminTeamsWrap>
    </AdminTeamContext.Provider>
  );
};

export default AdminTeamsShow;
